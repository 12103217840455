import axios from "axios";
import React, {Component} from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate 
} from "react-router-dom";
import {
    CircularProgress,
} from "@mui/material"
import UserContext, { ContainerProvider } from "./Context";
import Login from "./Login"
import Bookings from "./Booking";
import i18n from "../translations/i18n";

const user = {fn: () => false};

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
        this.pullUsers = this.pullUsers.bind(this);

        this.state = {
            user: {},
            isAuth: false,
            loading: true,
            users: false,
        };
    }

    logout() {
        this.setState(state => ({
            loading: true,
        }));
        axios.post("/users/logout").then(
            this.setState(state => ({
                user: {},
                isAuth: false,
                loading: false,
                users: false,
            }))
        ).catch(err => console.log(err));
    }

    login(event) {
        event.preventDefault();
        axios.post("/api/users/login", {
            "email": event.target.elements.namedItem('email').value,
            "password": event.target.elements.namedItem('password').value
        }).then((resp) => {
            i18n.changeLanguage(resp.data.language).then(t => {
                //console.log("Language set to", resp.data.language);
            })
            this.setState(state => ({
                user: {...state.user, ...resp.data}, 
            }), (state) => this.pullUsers({...state, loading: false, isAuth: true}));
        }).catch((err) => {
            if (err.response) {
                this.setState((state) => {
                    return {user: {...state.user, err: err.response.data.error}, isAuth: false}
                });
            }
        });
    }

    pullUsers(s) {
        return axios.get(`/api/users?pagination=false`).then((resp) => {
            this.setState(state => ({
                ...state, ...s,
                users: resp.data['hydra:member']
            }))
        }).catch(err => {
            this.setState(state => ({
                users: false,
            }))
        })
    }

    componentDidMount() {
        axios.get('/api/users/ping').then((resp) => {
            i18n.changeLanguage(resp.data.language).then(t => {
                //console.log("Language set to", resp.data.language);
            })
            this.setState(state => ({
                user: {...state.user, ...resp.data}, 
            }), (state) => this.pullUsers({...state, loading: false, isAuth: true}));
        }).catch(err => {
            this.setState(state => ({
                user: {...state.user}, 
                isAuth:false,
                loading: false,
                users: false,
            } ));
        });
    }

    render() {

        if (this.state.user?.roles?.includes('ROLE_TEST'))
            restoreLogger();

        return <ContainerProvider>
            {this.state.loading ? 
                <CircularProgress color={"secondary"} style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%"
                    }}
                />
            :
            <UserContext.Provider value={{...this.state.user, ...{users: this.state.users, logout: this.logout}}}>
                    <Router>
                        <Routes>
                            <Route exact path="/" element={
                                <Navigate to={(this.state.isAuth) ? "/bookings" : "/login"} />
                            } />
                            <Route path="/login" element={(this.state.isAuth) ? 
                                <Navigate to={"/bookings"} />
                            :
                                <Login login={this.login} err={this.state.user.err} />
                            } />
                            <Route path="/bookings/*" element={(!this.state.isAuth) ? 
                                <Navigate to={"/login"} />
                                :
                                <Bookings lang={this.state.user.language} />
                            } />
                        </Routes>
                    </Router>
                </UserContext.Provider>
            }
        </ContainerProvider>
    }
}

const _console = {
    loggers: {},
    removed: false,
};

const restoreLogger = () => _console.removed && Object.keys(_console.loggers).forEach(t => console[t] = _console.loggers[t]) && !(_console.removed = false);

const removeConsole = (c => {

    if (_console.removed)
        return;

    _console.loggers = Object.assign({}, c);

    Object.keys(c).forEach(t => {

        if (!(c[t].bind ?? false))
            return;

        c[t] = () => {}
    });
    _console.removed = true;
})(console);

export default Page;