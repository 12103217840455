import React, { createContext, useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import axios from "axios";

import { YearMonth, YearMonthDay } from "../DateTime";
import { groupBy } from "core-js/actual/array/group-by";

const ProjectsContext = createContext(null);
const ProjectsDispatchContext = createContext(null);
const ProjectsLoaderContext = createContext(null);
const ProjectMonthContext = createContext(null);
const ProjectSetMonthContext = createContext(null);

const projectsReducer = (projects, action) => {
    switch (action.type.toUpperCase()) {
        case 'ADD':

            const lpidxs    = Object.keys(projects);
            const pidxs     = Object.keys(action.bookings);

            if (lpidxs.length && pidxs.length && (lpidxs.at(-1) !== pidxs.at(-1)))
                projects[lpidxs.at(-1)].loaded = true;
            else if (lpidxs.length !== 0 && pidxs.length === 0)
                projects[lpidxs.at(-1)].loaded = true;

            pidxs.forEach(pidx => {

                const project = projects[pidx] || {
                    bookings: {},
                    loaded: pidxs.at(-1) != pidx
                }

                const ymb = action.bookings[pidx].groupBy(b => YearMonth(b.checkout)); 

                Object.keys(ymb).forEach(ym => {
                    project.bookings[ym] = Object.values([
                        ...(project.bookings[ym] || []), ...ymb[ym]
                    ].reduce(
                        (a,b) => ({...a, [b.id]: b}), {}
                    )).toSorted((b1, b2) => new Date(b1.checkout) - new Date(b2.checkout))
                })
                projects[pidx] = project;
            })

        break;
        case 'COMPLETE':

            const project = (projects[action.idx] || false);

            if (!project)
                break;

            const bookings = project.bookings[action.month] || false;

            if (!bookings)
                break;

            action.bookings.forEach(b1 => {
                const ym = YearMonth(b1.checkout);

                const bidx = bookings.findIndex(b2 => b2.id == b1.id);
                
                if (bidx == -1)
                    return;

                bookings[bidx] = b1;
                
            })

            project.bookings[action.month] = bookings;
            projects[action.idx] = project;

        break;
        case 'CLEAR':
            projects = {};
            console.log("CLEAR", projects);
        break;
    }

    return {...projects};
}

const ProjectsProvider = ({ children }) => {

    const renderAfterCalled = useRef(false);

    const [projects, dispatch] = useReducer(projectsReducer, {});

    const [loader, setLoader] = useState({
        loading: true,
        itemsLoaded: 0,
        totalItems: 0
    })

    const [month, setMonth] = useState(new Date(Date.now() + 24 * 3600 * 1000));

    const checkout = YearMonthDay(month);

    const wuhanFuckingCovidStateSetter = (nState) => {
        if (!nState?.loading) dispatch({
            type: 'add', 
            bookings: {}
        })

        setLoader(state => ({
            ...state, ...nState, 
            itemsLoaded: state.itemsLoaded + (nState?.itemsLoaded || 0),
        }));
    }

    const loadBookings = async (url) => {
        // console.log("Load: ", url);
        await axios.get((url)).then(resp => {

            dispatch({...{
                type: 'add',
                bookings: resp['data']['hydra:member'].groupBy(b => b.property.section)
            }})
    
            wuhanFuckingCovidStateSetter({
                loading: (resp['data']['hydra:view']['hydra:next']?.length != 0),
                itemsLoaded: resp['data']['hydra:member'].length,
                totalItems: resp['data']['hydra:totalItems']
            });
            
            if (resp['data']['hydra:view']['hydra:next'])
                loadBookings(
                    decodeURI(resp['data']['hydra:view']['hydra:next'])
                );
            else
                wuhanFuckingCovidStateSetter({loading: false });
    
        }).catch(err => {
            console.log("Error", err)
            wuhanFuckingCovidStateSetter({loading: false });
        });
    }

    const initLoading = () => {
        const initUrl = `/api/bookings.jsonld?order[property.section]=asc&order[checkout]=asc&exists[invoiceEntry]=false&blocking=false&checkout[before]=${checkout}`;
        if (renderAfterCalled.current)
            loadBookings(initUrl, dispatch)
        renderAfterCalled.current = true;
    }

    // useEffect(() => {
    //     console.log("CLEAR", month);
    //     if (Object.keys(projects).length != 0)
    //         dispatch({type: 'clear', bookings: {}});
    // }, [month])

    useEffect(() => {
        initLoading();
    }, [])

    return <ProjectMonthContext.Provider value={month}>
        <ProjectSetMonthContext.Provider value={setMonth}>
            <ProjectsContext.Provider value={projects}>
                <ProjectsDispatchContext.Provider value={dispatch}>
                    <ProjectsLoaderContext.Provider value={loader}>
                        {children}
                    </ProjectsLoaderContext.Provider>
                </ProjectsDispatchContext.Provider>
            </ProjectsContext.Provider>
        </ProjectSetMonthContext.Provider>
    </ProjectMonthContext.Provider>
}

export const useProjectEndMonth = () => {
    return useContext(ProjectMonthContext);
}

export const useProjectSetEndMonth = () => {
    return useContext(ProjectSetMonthContext);
}

export const useProjects = () => {
    return useContext(ProjectsContext);
}

export const useProjectsDispatch = () => {
    return useContext(ProjectsDispatchContext);
}

export const useProjectsLoader = () => {
    return useContext(ProjectsLoaderContext);
}

export default ProjectsProvider;