import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from 'i18next-resources-to-backend';
// const Encore = require('@symfony/webpack-encore');
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(resourcesToBackend((language, namespace) => import(`./${language}/${namespace}.json`)))
    .init({
        fallbackLng: 'en',
        debug: false, //!Encore.isProduction,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

  export default i18n;
