import React from "react";
import {
    Avatar,
    Alert,
    AlertTitle,
    Box,
    Button,
    CssBaseline,
    Link,
    TextField,
    Typography,
} from "@mui/material";
import {useStyles} from "./Styles";
import { CrisisAlert, CrisisAlertOutlined, LockOutlined } from "@mui/icons-material";

const Login = (props) => {
    const classes = useStyles();

    // const btheme = coreTheme();
    // const ctheme = createTheme();
    // console.log(useTheme(), classes);
    const logo = require('../../img/gv.logo.png');
    // console.log("Errr", props.err);
    return (
        <div>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <img src={logo} style={{width: "50%", maxWidth: "300px", margin: "10px"}}/>
                {(props.err) ? (
                    <Alert icon={<CrisisAlertOutlined />} severity="error" title={props.err?.tttle}>
                        {props.err.title ? <AlertTitle>{props.err.title}</AlertTitle> : <></>}
                        {props.err?.message || props.err}
                    </Alert>
                ) : <></>}
                <form className={classes.form} noValidate onSubmit={props.login}>
                    <TextField
                        variant={"outlined"}
                        margin={"normal"}
                        required
                        fullWidth
                        id={"email"}
                        label={"Email address"}
                        name={"email"}
                        autoComplete={"email"}
                        autoFocus
                    />
                    <TextField
                        variant={"outlined"}
                        margin={"normal"}
                        required
                        fullWidth
                        id={"password"}
                        label={"Password"}
                        name={"password"}
                        type={"password"}
                        autoComplete={"current-password"}
                    />
                    <Button
                        type={"submit"}
                        fullWidth
                        variant={"contained"}
                        color={"primary"}
                        className={classes.submit}
                        onSubmit={(event) => login(event)}
                    >
                        Sign in
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Typography variant={"body2"} color={"textSecondary"} align={"center"}>
                    {'Copyright © '}
                    <Link color={"inherit"} href={"https://vertskap.giaever.online"}>
                        Giæver Vertskap AS
                    </Link>
                    {" " + new Date().getFullYear() + "."}
                </Typography>
            </Box>
        </div>
    );
};

export default Login;
