import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import { Add, Cached, ExpandMore, Send, SyncProblem } from "@mui/icons-material";
import { useProjectsDispatch } from "./ProjectContext";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import Columns, { calculatePlatformFeePercentage, parseFloatFix, parseFloatToFixed, setCleaningFee, setInvoicing } from "./ProjectInvoiceColumns";
import axios from "axios";

const _calculateCleaningFee = (row) => {
    let h = 450.76;
    let vh = 1
    let time = (row.timeUsage ?? row.property.cleaningDuration) + (row.drivingTimeUsage ?? row.property.drivingDuration);
    time = (time / 1000 /  60 / 60 ) % 24;

    let price = parseFloatFix(parseFloatToFixed(parseFloatFix(parseFloatToFixed(time * h, 2)) * 1.25, 2));

    if (row.property.vatRegistered)
        price = Math.ceil(price / 1.12)

    if (row.calendarSource?.toLowerCase().includes('booking'))
        price = row.property.vatRegistered ? price * 1.12 * 1.179 : price * 1.179;
    
    return Math.ceil(price)
}

const FooterButtons = (props) => (
    <Box align={"right"} style={{padding: "1em"}}>
        <ButtonGroup>
            <AddInvoiceEntryButton {...props} />
            <SendInvoiceButton {...props} />
        </ButtonGroup>
    </Box>
)

const SendInvoiceButton = ({apiRef, rowsSelected, idx, month, bookings, setBookings, setAlerts, alerts}) => {
    const projectDispatch = useProjectsDispatch();
    const [disabledButton, setDisabledButton] = useState(false);
    const [hasAlerts, setHasAlerts] = useState(false);

    useEffect(() => {
        if (alerts)
            setHasAlerts(state => alerts);
    }, [ alerts ]);

    useEffect(() => {
        if (hasAlerts)
            setTimeout(() => setHasAlerts(false), 3000);
    }, [ hasAlerts ])

    const handleClick = (e) => {
        setDisabledButton(state => true);
        e.preventDefault();
        const models = Array.from(
            apiRef.current.getRowModels().values()
        ).filter(b => (b?.invoicing?.complete||false) == false);

        const complete = models.filter(b => rowsSelected.includes(b.id) && !b.invoicing?.complete).map(b => ({
            ...b,
            invoicing: {
                ...b.invoicing,
                complete: true
            }
        }))

        const makeFloatToInt = (v) => {
            return parseInt(parseFloat(v.toFixed(2)) * 100);
        }

        const bookings = complete.map(b => ({
            booking: b['@id'],
            bookingTotal: makeFloatToInt((b.invoicing?.booking_total || 0.0) / 1.12),
            cleaningFee: makeFloatToInt(b.property.vatRegistered === true && !b.calendarSource?.toLowerCase().includes('booking') ? (b.invoicing?.cleaning_fee||0.0) : (b.invoicing?.cleaning_fee||0.0) / 1.12),
            vat: makeFloatToInt(b.invoicing?.vat || 0),
            hostShare: b.invoicing?.host_share,
            guests: b.invoicing?.guests,
            platformFee: makeFloatToInt(b.property.vatRegistered === true || b.calendarSource?.toLowerCase().includes('booking') === true ? (b.invoicing?.platform_fee||0.0) : (b.invoicing?.platform_fee||0.0) / 1.25),
            bookingNumber: `${b.invoicing?.booking_number}`
        }))

        axios.interceptors.request.use(request => {
            if (request.url.includes('/api/invoices'))
                console.log('Starting Request', JSON.stringify(request, null, 2))
            return request
            })

        axios.post('/api/invoices', {
            property: `${complete[0].property['@id']}`,
            month: `${month}-01`,
            invoiceEntries: bookings
        }).then(resp => {
            setBookings(state => {
                let newState = [...state];
                complete.forEach(b1 => {
                    const id = state.findIndex(b2 => b2.id == b1.id)
                    if (id >= 0)
                        newState[id] = b1;
                })
                return newState;
            });

            setAlerts([])

            projectDispatch({
                type: 'complete',
                idx: idx,
                month: month,
                bookings: complete
            });
            setDisabledButton(false);
        }).catch(err => {
            console.log("Err", err);
            setDisabledButton(false);
            const errors = err?.response?.data?.violations||[];
            if (errors.length > 0) {
                
                setAlerts(state => (errors.map((e, i) => {
                    const spl = e.propertyPath.split('.')
                    let booking = {};
                    let field = {
                        field: e.propertyPath, 
                        field_name: err?.response?.data['hydra:title']
                    }
                    if (spl.length >= 2) {
                        const idx = spl[0].match(/\[([\d]+)\]/)
                        booking = complete.at(idx[1])
                        field = {
                            field_name: spl[1].replace(/([^a-z])/g, ' $1').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() }),
                            field: spl[1].replace(/([^a-z])/g, '_$1').toLowerCase(),
                        }
                    }
                    return {
                        idx: i,
                        bidx: booking?.id ?? -1,
                        ...field,
                        message: e.message,
                        code: e.propertyPath
                    }
                }).reduce((arr, item) => {
                    const bidx = arr.findIndex(a => a.bidx == item.bidx);
                    if (bidx > -1) arr[bidx].items = arr[bidx].items.concat(item);
                    else arr = arr.concat({
                        bidx: item.bidx, 
                        severity: 'error', 
                        items: [item]
                    })
                    return arr
                }, [])))
            } else
                setAlerts(state => errors.concat([{
                    bidx: -1,
                    severity: 'error',
                    items: [{
                        idx: 0,
                        field: err.name,
                        field_name: err?.response.data['hydra:title'],
                        message: err?.response.data['hydra:description'],
                        code: err?.message
                    }]
                }]))
        })
    }

    return <Button 
        variant="contained"
        color={hasAlerts ? 'error' : 'primary'}
        endIcon={hasAlerts ? <SyncProblem /> : disabledButton ? <Cached sx={{
            animation: "spin 2s linear infinite",
            "@keyframes spin": {
                "0%": {
                    transform: "rotate(0deg)",
                },
                "100%": {
                    transform: "rotate(360deg)",
                }
            }}}
        /> : <Send />}
        disabled={disabledButton || rowsSelected.length == 0 || rowsSelected.length == bookings.filter(b => b?.invoicing?.complete == true).length}
        onClick={handleClick}>
            Send invoice
    </Button>
}

const AddInvoiceEntryButton = ({month, setBookings}) => {

    const handleClick = (e) => {

        const yyyymm =  month.split('-')

        const date = (new Date())
        date.setMonth(parseInt(yyyymm[1]) - 1)
        date.setFullYear(yyyymm[0])
        date.setDate(1)

        setBookings(state => {
            return state.slice(0, -1).concat([{
                id: `${state[0].property?.id}-${state.length}`,
                property: state[0].property,
                calendarSource: 'internal.org',
                checkout: date,
                invoicing: {
                    booking_number: ""
                }
            }]).concat(state.slice(-1))
        })
    }

    return <Button 
        disabled
        variant="outlined"
        startIcon={<Add />}
        onClick={handleClick}
    >
        Add line
    </Button>
}

const isCalculationField = (params) => {
    return ['SUBTOTAL', 'TOTAL'].includes(params.id);
}

const fnCompleted = (b, t) => (
    !isCalculationField(b) && (b.invoicing?.complete || (t ? !t : t)) === t
)

const PromptEdited = ({open, stayFn, leaveFn}) => {
    return <Dialog
        open={open}
        onClose={stayFn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          You've made edits
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have made edits to the fields, which <b>will</b> be lost if you leave this view. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={stayFn}>
            Stay
          </Button>
          <Button onClick={leaveFn} autoFocus>
            Leave
          </Button>
        </DialogActions>
      </Dialog>
}

const ProjectMonth = ({expand, setExpanded, month, nextMonth, idx, data, isEditing, setIsEditing}) => {

    const date = new Date(month);

    const apiRef = useGridApiRef()
    const [rowSelection, setRowSelection] = useState([])
    const [bookings, setBookings] = useState([])
    const [alerts, setAlerts] = useState([])
    const [showIsEditingPrompt, setShowIsEditingPrompt] = useState(false);

    const calculateField = (field) => {
        const models = Array.from(apiRef.current.getRowModels().values()).filter(f => !isCalculationField(f))
        return models.reduce((a,b) => a + (b?.invoicing[field]||0), 0)
    }

    useEffect(() => {
        setBookings(data.map((e, idx) => setInvoicing(
            setCleaningFee({
                row: {
                    ...e,
                    lnr: idx + 1,
                    shownExcVat: e.property.vatRegistered && !e.calendarSource?.toLowerCase().includes('booking'),
                    invoicing: {
                        platform_fee: 0,
                        payout_total: 0,
                        vat: 0,
                        booking_total: 0,
                        ...e.invoicing
                    }
                }, 
                value: e.invoicing?.cleaning_fee ?? _calculateCleaningFee(e)
            }), {
                booking_number: (row) => (row.invoicing.booking_number ?? row.bookingNumber) ?? (row.calendarSource?.toLowerCase().includes('google') ? e.id : ""),
                guests: (row) => row.guests || "",
                host_share: (row) => row.property?.hostShare || 0
            }
        )).concat([{
            id: "TOTAL",
            label: "Total",
            fn: calculateField,
            value: 0,
            col_span: 5,
        }]))
        setRowSelection(data.filter(b => fnCompleted(b, true)).map(b => b.id))
    }, [data])

    useEffect(() => {
        if (bookings.length != 0) {
            if (isEditing)
                setIsEditing(false);
            if (showIsEditingPrompt)
                setShowIsEditingPrompt(false);
            setExpanded(nextMonth)
        }
    }, [bookings.length != 0 && bookings.filter(b => fnCompleted(b, false)).length == 0])

    const vatRegistered = bookings?.filter(b => b?.property?.vatRegistered === true).length > 0 || false;

    const changeMonthFn = () => {
        if (isEditing)
            setIsEditing(false);
        if (showIsEditingPrompt)
            setShowIsEditingPrompt(false);
        setExpanded(expand == month ? false : month)
    };

    // useEffect(() => console.log("Now editing", isEditing), [isEditing]);

    return <Accordion expanded={expand == month} onChange={() => {
        if (!isEditing)
            changeMonthFn();
        else if (!showIsEditingPrompt)
            setShowIsEditingPrompt(true);
        return null;
    }}>
        <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`panel-${month}-content`}
            id={`panel-${month}-header`}
    >
            <Typography sx={{color: 'text.secondary'}}>
                {date.toLocaleString(undefined, {month: 'long'})} {date.getFullYear()}
            </Typography>
            <PromptEdited 
                open={showIsEditingPrompt}
                leaveFn={() => changeMonthFn()} 
                stayFn={() => setShowIsEditingPrompt(false)}
            />
        </AccordionSummary>
        <AccordionDetails>
            <Grid container spacing={1} style={alerts.length > 0 || bookings.filter(b => !isCalculationField(b) && (b.property?.invoiceReady || false) == false).length > 0 ? {marginBottom: "6px"} : {}}>
                { bookings.filter(b => !isCalculationField(b) && (b.property.invoiceReady || false) == false).length > 0 ?
                    <Grid item xs={12} key={"not-invoice-ready"}>
                        <Alert severity={'warning'}>
                            <AlertTitle>Property is not «Invoice ready»</AlertTitle>
                            This property is <b>not yet</b> invoice ready, so you can not produce invoices for it. Contact Joachim to
                            get it ready for invoicing.
                        </Alert>
                    </Grid> : <></>
                }
                {alerts.map(a => 
                    <Grid item xs={12}>
                        <Alert severity={a.severity} onClose={() => setAlerts(state => state.filter(alert => alert.bidx != a.bidx))}>
                            <AlertTitle>Line #{parseInt(a.bidx) + 1}</AlertTitle>
                            <ul>
                                {a.items.map(f => <li key={`${f.idx}-${a.bidx}-${f.field}`}><b>{f.field_name}:</b> {f.message}</li>)}
                            </ul>
                        </Alert>
                    </Grid>    
                )}
            </Grid>
            <Box sx={{
                '& .bold': {
                    fontWeight: 500
                }, '& .light': {
                    fontWeight: 400,
                    color: '#a6a6a6'
                }, '& .error': {
                    bgcolor: "#fdeded !important",
                    color: '#5f2120',
                }, '& .success': {
                    bgcolor: "#eef8f5 !important",
                    color: '#1f3f6b'
                }, '& .warning ': {
                    bgcolor: "#fff4e5 !important"
                }
            }}>
                <DataGrid columns={Columns.map(c => ({
                    ...c,
                    sortable: false
                }))}
                    apiRef={apiRef}
                    rows={bookings}
                    loading={bookings.length == 0}
                    autoHeight={true}
                    columnVisibilityModel={{
                        id: false,
                        lnr: false,
                        vat: vatRegistered,
                    }}
                    disableColumnMenu={true}
                    getCellClassName={params => {
                        if (isCalculationField(params))
                            return params.id == 'TOTAL' ? 'light' : 'bold';

                        let classes = [];
                        
                        let value;
                        if (params.field == 'platform_fee' && (value = parseFloatFix(calculatePlatformFeePercentage(params))) > 0) {
                            if (params.row?.calendarSource?.toLowerCase().includes('airbnb') && value != 3.75)
                                classes.push('warning');
                            else if (params.row?.calendarSource?.toLowerCase().includes('booking') && ![16.4, 19.4].includes(value))
                                classes.push('warning');
                            else
                                classes.push('sucess');
                        }

                        if (params.isEditable)
                            classes.push("bold")
                        else
                            classes.push('light')

                        let balert = []
                        if ((balert = alerts.filter(a => a.items.filter(b => parseInt(b.id) == parseInt(params.id).length > 0))).length != 0)
                            classes.push(balert[0].items.filter(f => ['calendarSource', f.field].includes(params.field)).length != 0 ? 'error' : 'success')

                        return classes
                    }}
                    
                    isCellEditable={(params) => {
                        if (params?.row?.property?.invoiceReady == false)
                            return false;

                        if (['internal', 'giaever', 'google'].includes(params.row?.calendarSource?.toLowerCase()))
                            return true;

                        if (isCalculationField(params))
                            return false;

                        if ('complete' in (params?.row?.invoicing||{}))
                            return false;

                        // console.log(params?.row, params?.field);

                        if (['booking_total', 'payout_total'].includes(params.field))
                            return params.row.calendarSource?.toLowerCase().includes('airbnb') && params.field == 'payout_total' || params.row.calendarSource?.toLowerCase().includes('booking') && params.field == 'booking_total' || params.row.calendarSource?.toLowerCase().includes('google');
                        
                        if (params.field == 'booking_number' && ['internal', 'giaever', 'google'].includes(params.row?.calendarSource?.toLowerCase()))
                            return false;

                        return true;
                    }}
                    slots={{
                        footer: FooterButtons,
                        loadingOverlay: LinearProgress
                    }}
                    slotProps={{
                        footer: {
                            apiRef: apiRef,
                            rowsSelected: rowSelection,
                            bookings: bookings,
                            setBookings: setBookings,
                            idx: idx,
                            month: month,
                            setAlerts: setAlerts,
                            alerts: alerts.length != 0
                        }
                    }}
                    isRowSelectable={p => {
                        if (isCalculationField(p))
                            return false;

                        if (p.row?.invoicing?.complete)
                            return false;

                        const fieldsValues = {
                            'booking_number': false,
                            'payout_total': false,
                            'platform_fee': false,
                            'booking_total': false,
                            'vat': false
                        };

                        const fields = Object.keys(fieldsValues);

                        fields.forEach(f => {
                            if ((p.row?.invoicing[f]??0) != parseFloatFix(0))
                                fieldsValues[f] = true;
                        });

                        const complete = fields.map(f => fieldsValues[f]).filter(f => f).length == fields.length;

                        if (complete)
                            return true;

                        const cs = p.row.calendarSource;
                        const guests = (p.row.guests||0);

                        if (cs.includes('airbnb') || cs.includes('booking')) {
                            return (
                                (guests > 0) &&
                                fields.map(f => fieldsValues[f]).filter(f => f).length == 0 && 
                                (["10000", "0"]).includes(`${p.row?.invoicing.host_share}`) &&
                                (p.row?.invoicing?.booking_number||"").length > 0
                            )
                        }
                        return true;
                    }}
                    checkboxSelection
                    disableRowSelectionOnClick={true}
                    onRowSelectionModelChange={(row) => setRowSelection(row)}
                    rowSelectionModel={rowSelection}
                    onCellEditStart={() => setIsEditing(true)}
                />
            </Box>
        </AccordionDetails>
    </Accordion>
}

export default ProjectMonth;
