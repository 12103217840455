
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

export const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  // submit: {
  //   margin: theme.spacing(3, 0, 2),
  // },
  // nested: {
  //   paddingLeft: theme.spacing(4),
  // },
    formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    }
}));

const theme = createTheme({
  palette: {
    primary: {
      light: "rgba(255,64,129, 1)",
      main: "rgba(245,0,87, 1)",
      dark: "rgba(197,17,98, 1)",
      contrastText: "rgba(255,255,255, 1)"
    },
    secondary: {
      light: "rgba(121, 134, 203, 1)",
      main: "rgba(63, 81, 181, 1)",
      dark: "rgba(48, 63, 159, 1)",
      contrastText: "rgba(255, 255, 255, 1)"
    },
    error: {
      light: "rgba(229,115,115, 1)",
      main: "rgba(244,67,54, 1)",
      dark: "rgba(211,47,47, 1)",
      contrastText: "rgba(255,255,255, 1)"
    },
    warning: {
      light: "rgba(255,183,77, 1)",
      main: "rgba(255,152,0, 1)",
      dark: "rgba(245,124,0, 1)",
      contrastText: "rgba(0,0,0, 1)"
    },
    info: {
      light: "rgba(100,181,246, 1)",
      main: "rgba(33,150,243, 1)",
      dark: "rgba(25,118,210, 1)",
      contrastText: "rgba(255,255,255, 1)"
    },
    success: {
      light: "rgba(129,199,132, 1)",
      main: "rgba(76,175,80, 1)",
      dark: "rgba(56,142,60, 1)",
      contrastText: "rgba(0,0,0, 1)"
    }
  }
});

export default theme;