import React, {useEffect, useState, useRef, useCallback} from "react";
import { styled } from "@mui/material/styles";

import {
    BrowserRouter as Router,
    useMatch,
    useNavigate,
} from "react-router-dom";

import {
    Box,
    Fade,
    Modal,
    Snackbar,
    Typography,
    Avatar,
    Card,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Button,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    ButtonGroup,
    Popper,
    Grow,
    Paper,
    ClickAwayListener
} from '@mui/material';

import ProjectsProvider, { useProjectEndMonth, useProjectSetEndMonth, useProjects, useProjectsLoader } from "./ProjectContext";
import { ArrowBackIos, ArrowDropDownCircle, ArrowForwardIos, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, Paid } from "@mui/icons-material";
import ProjectMonth from "./ProjectMonth";
import { useContainerContext } from "../Context";
import { YearMonth, YearMonthDay } from "../DateTime";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const ProjectBookings = ({project}) => {

    const bookingMonths = Object.keys(project.bookings);
    const [current, setCurrent] = useState(`${bookingMonths[0]}`)
    const [isEditing, setIsEditing] = useState(false);
    
    const monthIdx = bookingMonths.findIndex(b => b == current) + 1;
    
    useEffect(() => {
        setCurrent(`${bookingMonths[0]}`)
    }, [project])

    return <>{Object.keys(project.bookings).map(ym => {
        return <ProjectMonth 
            key={`${ym}`} 
            expand={current} 
            setExpanded={setCurrent}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            month={ym}
            nextMonth={bookingMonths.at(monthIdx % bookingMonths.length)}
            idx={project.idx}
            data={[...project.bookings[ym]]}
        />   
    })}</>
}

const Project = React.memo(({project, changeProjectHandler, endDate, setMonth}) => {

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => setOpen(state => !state);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setOpen(false);
      };

    // console.log("Render", project.idx, "with", Object.keys(project.bookings).length, "months with", Object.keys(project.bookings).map(b => b.length).reduce((p, c) => p + c, 0), "bookings", project.bookings);
    return <Card>
        <List>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <Paid color="secondary" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    secondary={<><b>Address:</b> <span>{project.property?.address.address}</span></>}
                    primary={<span style={{color: '#9c27b0'}}><b>Project: </b><span>{project.idx}</span></span>}
                />
                <List>
                    <ListItem ref={anchorRef}>
                        <ButtonGroup>
                            <Button
                                disabled={project.idx == project.fidx}
                                color={"primary"}
                                onClick={() => changeProjectHandler(project.fidx)}
                                startIcon={<KeyboardDoubleArrowLeft />}
                            >First</Button>
                            <Button
                                disabled={project.idx == project.pidx}
                                startIcon={<ArrowBackIos />}
                                color={"primary"}
                                onClick={() => changeProjectHandler(project.pidx)}
                            >Previous</Button>
                            <Button 
                                variant="contained"
                                color="secondary"
                                aria-controls={open ? 'split-button-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                                endIcon={<ArrowDropDownCircle />}
                                onClick={handleToggle}
                            >
                                {YearMonthDay(endDate)}
                            </Button>
                            <Button 
                                disabled={project.idx == project.nidx}
                                endIcon={<ArrowForwardIos />}
                                color={"primary"}
                                onClick={() => changeProjectHandler(project.nidx)}
                            >Next project</Button>
                            <Button
                                disabled={project.idx == project.lidx}
                                color={"primary"}
                                endIcon={<KeyboardDoubleArrowRight />}
                                onClick={() => changeProjectHandler(project.lidx)}
                            >Last</Button>
                        </ButtonGroup>
                        <Popper
                            sx={{
                                zIndex: 1,
                            }}
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                        >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <Box>
                                            <LocalizationProvider dateAdapter={AdapterMoment} style={{padding: '10px'}}>
                                                <StaticDatePicker
                                                    sx={{
                                                        '.MuiPickersToolbar-root': {
                                                            color: '#1565c0',
                                                            borderRadius: '2px',
                                                            borderWidth: '1px',
                                                            borderColor: '#2196f3',
                                                            border: '1px solid',
                                                            backgroundColor: '#90caf9',
                                                        },
                                                    }}
                                                    value={endDate}
                                                    onChange={(e) => setMonth(e.toDate())}
                                                    renderInput={(p) => <TextField {...p} />}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                        </Popper>
                    </ListItem>
                </List>
            </ListItem>
        </List>
        <ProjectBookings project={project} />
    </Card>
})

const ProjectLoader = ({projects, idx, loading}) => {

    const loader = useProjectsLoader();

    const length = Object.keys(projects??{}).length;

    const req = length > 1 || (loader.loading && length == 0);

    if (!loading && length > 1)
        return <Snackbar
            open={loader.loading}
            TransitionComponent={Fade}
            message={`${loader.itemsLoaded} out of ${loader.totalItems} bookings within ${length} projects loaded`}
            key={Fade.name}
        />

    return <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={loader.loading}
        >
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        }}>
            <Typography id="modal-title" variant="h6" component="h2">
                {idx ? `Searching for project ${idx}` : 'Loading'} { '.'.repeat(length % 4)}
            </Typography>
            <Typography id="modal-description">
                {loader.itemsLoaded} out of {loader.totalItems} within {length} project loaded
            </Typography>
        </Box>
    </Modal>
}

const sleep = async (t) => {
    return new Promise(resolve => { setTimeout(resolve, t) });
}

const Projects = () => {
    const projects = useProjects();
    const loader = useProjectsLoader();
    const [project, setProject] = useState(false);

    const endDate = useProjectEndMonth();
    const setMonth = useProjectSetEndMonth();

    const match = useMatch('/bookings/invoicing/*');

    let history = useNavigate();

    const projectKeys = Object.keys(projects);
    const mp = match?.params["*"]|| "";

    // console.log(projects, loader);

    const chooseProject = (idx) => {
        // console.log("Req", idx);
        if (projectKeys.length == 0) 
            return false;

        let kidx = 0;

        if (idx) {
            kidx = projectKeys.indexOf(idx.toUpperCase());
        }

        const key = projectKeys[kidx];

        if (!key)
            return false;

        return {
            fidx: projectKeys[0] || key,
            idx: key,
            pidx: kidx > 0 ? projectKeys[kidx - 1] : key,
            nidx: (kidx + 1) < projectKeys.length ? projectKeys[kidx + 1] : key,
            lidx: projectKeys[projectKeys.length - 1] || key,
        };
    }

    const _setProject = async (idx) => {
        const key = chooseProject(idx);

        const testKey = (project, key) => !project || (project && key && (key.idx != project.idx || project.bookings.length != projects[key.idx].bookings.length));

        if (testKey(project, key))
            setProject(state => ({
                ...key,
                ...projects[key.idx]
            }));
    };
    
    const setProjectByIdx = useCallback((idx) => {
        history(`${match.pathnameBase}/${idx}`)
    }, [mp, projectKeys.includes(mp), projectKeys.length > 0]);

    useEffect(() => {
        if (mp.length == 0 && projectKeys.length > 0)
            setProjectByIdx(projectKeys[0])
        else if (projectKeys.includes(mp))
            _setProject(mp)
    }, [mp, projectKeys.includes(mp) && projects[mp].loaded, projectKeys.length > 0])

    if (project)
        return <>
            <Project 
                project={project}
                changeProjectHandler={setProjectByIdx}
                endDate={endDate}
                setMonth={setMonth}
            />
            <ProjectLoader projects={projects} />
        </>
    else if (loader.loading)
        return <ProjectLoader loading={true} idx={mp} projects={projects} />
    else 
        return <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={3}>
                <Card style={{width: "300px", marginTop: "30vh"}}>
                    <CardMedia
                        component={"img"}
                        height="140"
                        image="https://assets.justinmind.com/wp-content/webp-express/webp-images/uploads/2020/04/best-404-pages.png.webp"
                    />
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Could not find the project
                        </Typography>
                        <Typography variant="h5" component="div" textAlign={"center"}>
                            «{mp}»
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            It might not exists or does not have any invoicable bookings.
                        </Typography>
                        <Typography variant="body2">
                            This error might also happen if there are now bookings that needs invoicing. Total amount of bookings {projectKeys.length}.
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
}

const Invoicing = (props) => {

    const setMaxWidth = useContainerContext()

    useEffect(() => setMaxWidth("lg"), [])

    return <ProjectsProvider>   
        <Projects />
    </ProjectsProvider>
}

export default Invoicing;
