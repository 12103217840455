
const dateTime = (d) => {
    if (typeof d == "string")
        return new Date(d == "now" ? "" : d);

    return d;
}

export const HourMinute = (date) => {

    date = dateTime(date);

    let h = date.getHours();
    let m = date.getMinutes();

    if (("" + h).length < 2 )
        h = "0" + h;
    if (("" + m).length < 2)
        m = "0" + m;

    return h + ":" + m;
}

export const DayMonthYear = (date) => {
    let ymd = YearMonthDay(date);

    ymd = ymd.split('-');

    return "" + ymd[2] + "." + ymd[1] + "." + ymd[0];
}

export const YearMonthDay = (date) => {

    date = dateTime(date);

    let month = (date.getMonth()+1),
        day = date.getDate();

    if (("" + month).length < 2)
        month = "0" + month;
    if (("" + day).length < 2)
        day = "0" + day;

    return "" + date.getFullYear() + "-" + month + "-" + day;
}

export const YearMonth = (date) => {
    return YearMonthDay(date).split('-').slice(0,2).join('-');
}

export const CreateTodayByTimestring = (day, time) => {
    const t = time.split('T')[1].split('+')[0];
    const tz = day.split('+')[1];
    return new Date(YearMonthDay(day) + 'T' + t + '+' + tz);
}
