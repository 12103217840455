import "flatpickr/dist/themes/material_red.css";
import axios from "axios";
import React, {useState, useEffect, useRef, Component, isValidElement} from "react";
import {
    Routes,
    Route,
    useMatch,
    useParams,
    useNavigate,
} from "react-router-dom";
import Invoicing from "./Invoicing/Invoicing";
import { pink, purple, red } from '@mui/material/colors';
import {
    Avatar,
    Alert,
    AlertTitle,
    Badge,
    Box,
    Button,
    ButtonGroup,
    CardContent,
    Card,
    CardActions,
    Checkbox,
    Chip,
    Collapse,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Link,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
    Select,
    Snackbar, 
    Stack,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from '@mui/material'
import {
    AccessAlarms,
    AlternateEmail,
    ArrowForward,
    Autorenew,
    AirlineSeatFlat,
    CalendarToday,
    Call,
    CallSplit,
    Check,
    Contacts,
    DepartureBoard,
    Expand,
    ExpandLess, 
    ExpandMore,
    Feedback,
    HighlightOff,
    History,
    Hotel,
    Logout,
    Login,
    Lock,
    LockOpen,
    MonetizationOn,
    NightShelter,
    Sms,
    StarRounded,
    SyncLock,
    SyncProblem,
    Timer,
    PendingActions,
    PersonPinCircle,
    Update,
    WarningAmber,
    WhatsApp,
    Announcement,
    GTranslateRounded,
    CloudSyncOutlined,
    ToggleOff,
    ToggleOn,
    CleaningServices,
    Preview,
    ReportProblem,
    SportsMartialArts,
    LockClock,
    BatteryUnknown,
    BatteryFull,
    Battery50,
    Battery20,
    Battery30,
    Battery60,
    Battery80,
    Battery90,
    BatteryAlert,
    OpenInBrowser,
    Close,
    ArrowForwardIos,
    ArrowBackIos,
    AccessAlarm,
    AlarmOn,
    AccessTime,
} from "@mui/icons-material";
import _moment, { localeData } from 'moment';
import { useStyles } from './Styles';
import UserContext, { useContainerContext } from "./Context";
import {YearMonthDay, HourMinute, DayMonthYear, CreateTodayByTimestring} from "./DateTime";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {Trans, useTranslation} from "react-i18next";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { 
    AirbnbIcon,
    BookingIcon,
    GoogleIcon,
    GVIcon
} from './Invoicing/ProjectInvoiceColumns'
import { styled } from "@mui/material/styles";
import ReactJson from "react-json-view";

const CustomTimePicker = ({inputAdornmentPosition, label, date, handler, readOnly, helperText, icon, ...props}) => {
    return (
        <LocalizationProvider sx={{...(props?.sx ?? {})}} dateAdapter={AdapterMoment}>
            <MobileTimePicker
                label={label||null}
                value={date}
                onChange={handler}
                readOnly={readOnly || props?.disabled}
                disabled={readOnly || props.disabled}
                renderInput={({sx, ...p}) => <TextField 
                    variant="filled" 
                    helperText={helperText || null}
                    sx={{'& > *:not(.Mui-disabled), & input:not(.Mui-disabled)': {
                        cursor: "pointer !important"
                    }}} {...p} 
                />}
                InputProps={{...props, [`${inputAdornmentPosition || 'start'}Adornment`]: <InputAdornment position={inputAdornmentPosition || 'start'}>
                        <IconButton edge={inputAdornmentPosition || 'start'}>
                            <AnyComponent component={icon || <AccessTime />} /> 
                        </IconButton>
                    </InputAdornment>
                }}
                className={[inputAdornmentPosition, props?.className].filter(c => c).join(' ')}
                InputAdornmentProps={{
                    position: inputAdornmentPosition
                }}
                sx={{backgroundColor: pink.A700}}
            />
        </LocalizationProvider>
    )
}

const NBooking = ({children, ...b}) => {
    // const [booking, setBooking] = useState({...b})
    return <>{children}</>
}

const AnyComponent = ({component, ...props}) => {
    return (isValidElement(component || <></>) ? 
        ({type, props}, rootProps) => (Ctype => <Ctype {...{...props, ...rootProps}} />)(type) :
        (Ctype, props) => <Ctype {...props} />
    )(component || <></>, props);
}

_moment.call(_moment.locales().includes('en') ? 'updateLocale' : 'defineLocale', ['en', {
    relativeTime: {
        future: '%s before',
        past: '%s after'
    }
}]);

// const moment = (...a) => _moment(...a);

const moment = (inp, user = null, {...localeData} = {}) => {

    if (Object.keys(localeData).length) {
        if (!_moment.locales().includes(user?.language || 'en'))
            _moment.defineLocale(user?.language || user || 'en', {...localeData});
        else
            _moment.updateLocale(user?.language || user || 'en', {...localeData});
    }

    return _moment(inp ?? null).local(true);
}

class Booking extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            nextCheckin: this.props.booking?.nextCheckin?.at || false,
            previousCheckout: this.props.booking?.previousCheckout?.at || false,
            checkin: this.props.booking.checkin,
            checkout: this.props.booking.checkout,
            cleaned: this.props.type.includes("checkout") ? this.props.booking?.clean || false : this.props.booking?.checkInReady,
            drivingTimeUsage: this.props.booking.drivingTimeUsage,
            timeUsage: this.props.booking.timeUsage,
            responsible: this.props.type.includes("checkout") ? (this.props.booking?.responsible || [])['@id'] || false : (this.props.booking?.previousCheckout?.responsible || [])['@id'] || false,
            guests: this.props.type.includes("checkout") ? (this.props.booking?.nextCheckin?.guests ?? null ) : (this.props.booking?.guests ?? null),
            bedrooms: this.props.type.includes("checkout") ? (this.props.booking?.nextCheckin?.bedrooms ?? this.props.booking?.bedrooms) : this.props.booking.bedrooms,
            guestsRequested: false,
            notes: Object.assign({}, ...Object.keys(this.props.booking.property?.notes || {}).map(n => ({[n]: this.props.booking.property.notes[n]}))),
            started: this.props.type.includes("checkout") ? (this.props.booking.timeStart || false) : (this.props.booking?.previousCheckout?.onGoing || false),
            survey: {
                cleaned: props.booking.clean,
                guests: props.booking.clean,
                rating: props.booking.rating,
                openings: props.booking.clean,
                heating: props.booking.clean,
                curtains: props.booking.clean,
                note: props.booking.property.notes != null && props.booking['@id'] in props.booking.property.notes ? props.booking.property.notes[props.booking['@id']] : "",
                markedNotes: {},
                noteFixedOpen: props.booking.property.notes != null ? Object.assign({}, ...Object.keys(props.booking.property.notes).map(n => ({[n]: false}))) : {},
            },
        }
        this.toggleOpen = this.toggleOpen.bind(this);
        this.markReady = this.markReady.bind(this);
        this.checkNote = this.checkNote.bind(this);
        this.setTimeStarted = this.setTimeStarted.bind(this);
        this.handleUpdateResponsible = this.handleUpdateResponsible.bind(this);
        this.handleUpdateGuests = this.handleUpdateGuests.bind(this);
        this.handleTimeUsageCleaning = this.handleTimeUsageCleaning.bind(this);
        this.handleTimeUsageDriving = this.handleTimeUsageDriving.bind(this);
        this.handleUpdateBedrooms = this.handleUpdateBedrooms.bind(this);
        this.handleUpdateCheckout = this.handleUpdateCheckout.bind(this);
        this.handleUpdateCheckin = this.handleUpdateCheckin.bind(this);
        this.handleUpdateNextCheckin = this.handleUpdateNextCheckin.bind(this);
        this.handleUpdatePrevCheckout = this.handleUpdatePrevCheckout.bind(this);
        this.handleRequestGuestAmount = this.handleRequestGuestAmount.bind(this);
        this.openNoteConfirmation = this.openNoteConfirmation.bind(this);
    }
    
    setTimeStarted(time) {
        this.setState(state => ({
            started: time,
        }))
    }

    checkNote(checked, noteId) {
        if (checked == true) {
            let notes = this.state.notes;
            delete notes[noteId];
            axios.put(this.props.booking['@id'], {
                "property": {
                    "id": this.props.booking.property["@id"],
                    "notes": notes,
                }
            }).then(resp => {
                this.setState(state => ({
                    survey: {...state.survey, 
                        markedNotes: {...state.survey.markedNotes, ...{
                            [noteId]: checked
                        }}
                    }
                }))
            }).catch (err => {
                this.props.openReLoginHandler(err.response.status == 401)
            })
        }
    }

    openNoteConfirmation(noteId) {
        this.setState(state => ({
            survey: {...state.survey, 
                noteFixedOpen: {...state.survey.noteFixedOpen, ...{
                    [noteId]: !state.survey.noteFixedOpen[noteId]
                }}
            }})
        )
    }

    answerSurvey(e, s, i) {
        let answer = {};
        if (s == "cleaned")
            answer = {cleaned: !this.state.survey.cleaned}
        else if (s == "guests")
            answer = {guests: !this.state.survey.guests}
        else if (s == 'openings')
            answer = {openings: !this.state.survey.openings}
        else if (s == 'heating')
            answer = {heating: !this.state.survey.heating}
        else if (s == 'curtains')
            answer = {curtains: !this.state.survey.curtains}
        else if (s == "rating") 
            answer = {rating: i}
        else if (s == "note")
            answer = {note: e.target.value}
        this.setState(state => ({survey: {...state.survey,
            ...answer
        }}));
    }

    toggleOpen() {
        this.setState(state => ({
            open: !state.open,
        }))
    }

    markReady(user) {
        const signer = user.users.find(u => u['@id'] == user['@id']);
        const note = (signer['@id'] != this.state.responsible ? ` - Signed by ${signer.name} (${signer['@id']})${this.state.survey?.note.length > 0 ? ': ' : ''}` : '') + this.state.survey.note;
        let param = {
            "clean": "" + this.state.survey.cleaned && this.state.survey.guests,
            "rating": this.state.survey.rating,
            "property": {
                "id": this.props.booking.property["@id"],
                "note": this.props.booking['@id'] + ':' + note,
            },
        };
        axios.put(this.props.booking['@id'], param).then(resp => {
            if (this.props.booking.nextCheckin?.id && this.state.bedrooms != this.props.booking.nextCheckin.bedrooms) {
                axios.put(`/api/bookings/${this.props.booking.nextCheckin.id}`, {
                    "bedrooms": this.state.bedrooms
                }). then(() => {
                    this.setState(state => ({
                        ...state,
                        booking: {...state.booking, ...resp.data.booking, clean: true},
                        open: false,
                        timeUsage: resp.data.timeUsage,
                        clean: true,
                        cleaned: true,
                        notes: {...state.notes, [this.props.booking['@id']]: note},
                        survey: {...state.survey, note: note, cleaned: true},
                    }))
                });
            } else {
                this.setState(state => ({
                    ...state,
                    booking: {...resp.data.booking, clean: true},
                    open: false,
                    timeUsage: resp.data.timeUsage,
                    clean: true,
                    cleaned: true,
                    notes: {...state.notes, [this.props.booking['@id']]: note},
                    survey: {...state.survey, note: note, cleaned: true},
                }));
            }
        }).catch (err => {
            this.props.openReLoginHandler(err.response.status == 401)
        })
    }

    handleUpdateResponsible(event) {
        const uid = event.target.value;
        axios.put(this.props.booking["@id"], {
            responsible: uid || null,
        }).then(resp => {
            this.setState(state => ({
                responsible: uid,
            }))
        }).catch (err => {
            this.props.openReLoginHandler(err.response.status == 401)
        })
    }

    handleUpdateGuests(guests, nextCheckin) {
        const g = guests.target?.value;

        if ((g || -1) < 0 || g.length == 0) {
            this.setState(state => {
                guests: 0
            });
            return;
        }

        axios.put(nextCheckin? "/api/bookings/" + this.props.booking.nextCheckin.id : this.props.booking["@id"], {
            guests: parseInt(g, 10) || 0
        }).then(resp => {
            this.setState({
                guests: resp.data.guests
            })
        }).catch (err => {
            this.props.openReLoginHandler(err.response.status == 401)
        })
    }

    handleTimeUsageCleaning(h, m) {
        axios.put(this.props.booking["@id"], {
            timeUsage: `PT${h}H${m}M`
        }).then(resp => {
            this.setState({
                timeUsage: (h * 60 * 60 + 60 * m) * 1000
            })
        }).catch (err => {
            this.props.openReLoginHandler(err.response.status == 401)
        })
    }

    handleTimeUsageDriving(h, m) {
        axios.put(this.props.booking["@id"], {
            drivingTimeUsage: `PT${h}H${m}M`
        }).then(resp => {
            this.setState({
                drivingTimeUsage: (h * 60 * 60 + 60 * m) * 1000
            })
        }).catch (err => {
            this.props.openReLoginHandler(err.response.status == 401)
        })
    }

    handleUpdateBedrooms(bedrooms, nextCheckin) {
        const b = bedrooms.target.value;
        axios.put(nextCheckin? "/api/bookings/" + this.props.booking.nextCheckin.id : this.props.booking["@id"], {
            bedrooms: parseInt(b, 10)
        }).then(resp => {
            this.setState({
                bedrooms: resp.data.bedrooms
            })
        }).catch (err => {
            this.props.openReLoginHandler(err.response.status == 401)
        })
    }

    handleUpdateCheckin(checkin) {
        this.updateCheckin(this.props.booking['@id'], checkin, resp => {
            this.setState({
                checkin: resp.data.checkin,
            })
        });
    }

    handleUpdateNextCheckin(checkin) {
        if (this.state.nextCheckin == null)
            return;

        this.updateCheckin(this.props.booking.nextCheckin?.id, checkin, resp => {
            this.setState({
                nextCheckin: resp.data.checkin
            });
        });
    }

    handleUpdateCheckout(checkout) {
        this.updateCheckout(this.props.booking['@id'], checkout, resp => {
            this.setState({
                checkout: resp.data.checkout,
            })
        });
    }

    handleUpdatePrevCheckout(checkout) {
        if (this.state.previousCheckout == null)
            return;

        this.updateCheckout(this.props.booking.previousCheckout?.id, checkout, resp => {
            this.setState({
                previousCheckout: resp.data.checkout
            });
        });
    }

    updateCheckin(id, checkinMoment, callbackFn) {
        axios.put(typeof id == "string" ? id : "/api/bookings/" + id, {
            checkin: checkinMoment.format("YYYY-MM-DD HH:mm:ssZ")
        }).then(resp => {
            callbackFn(resp);
        }).catch (err => {
            this.props.openReLoginHandler(err.response.status == 401)
        })
    }

    updateCheckout(id, checkoutMoment, callbackFn) {
        axios.put(typeof id == "string" ? id : "/api/bookings/" + id, {
            checkout: checkoutMoment.format("YYYY-MM-DD HH:mm:ssZ")
        }).then(resp => {
            callbackFn(resp);
        }).catch (err => {
            this.props.openReLoginHandler(err.response.status == 401)
        })
    }

    handleRequestGuestAmount() {
        axios.post(this.props.booking['@id'] + "/request_guests")
        .then(resp => {
            this.setState({
                guestsRequested: true,
            })
        }).catch (err => {
            this.props.openReLoginHandler(err.response.status == 401)
        })
    }

    render() {

        const {t, user} = this.props;

        if (!user) {
            this.props.openReLoginHandler(true)
            return <></>
        }

        const outtime = HourMinute(this.props.type.includes("checkout") ? 
            this.state.checkout : 
            this.props.booking.checkin
        ); 

        const passed = new Date(
            this.props.type.includes("checkout") ? 
                this.props.booking.checkout : this.props.booking?.previousCheckout?.at
        ) < new Date();

        let icon = passed && (this.state?.started || false) ? 
            <CleaningServices /> : <AccessAlarms />;

        if ((this.props.type.includes("checkout") && (this.props.booking.clean || this.state.survey.cleaned || this.state.cleaned || this.state?.ready)) || (this.props.type.includes("checkin") && this.props.booking.ready))
            icon = <Check />;

        const roleBasedIcon = user.roles.includes('ROLE_ADMIN') ? 
            this.props.type.includes('checkout') ? 
                this.state.guests === null && (this.state?.nextCheckin || false) ? <Hotel /> :
                    !this.state?.responsible ? <SportsMartialArts /> :
                        this.state.cleaned && !((this.state.survey?.markedNotes || [])[this.props.booking['@id']] || false) && ((this.state.notes || [])[this.props.booking['@id']] || false) ? 
                            <Announcement /> : icon
            : this.state.guests === null && (this.state?.nextCheckin || false) ? <Hotel /> :
                this.state?.started && !this.state.cleaned ? icon :
                    Object.keys(this.state?.notes || {}).length > 0  ? 
                        <Announcement /> : icon
        : user.roles.includes('ROLE_MANAGER') ?
            !this.state?.responsible ? <SportsMartialArts /> :
                this.state.cleaned && !((this.state.survey?.markedNotes || [])[this.props.booking['@id']] || false) && ((this.state.notes || [])[this.props.booking['@id']] || false) ? 
                    <Announcement /> : 
                        this.state.guests === null && (this.state?.nextCheckin || false) ? 
                            <Hotel /> : icon
        : icon

        let same_day = false;
        
        if (this.state.nextCheckin)
            same_day = DayMonthYear(this.props.booking.checkout) == DayMonthYear(this.state.nextCheckin);

        const is_today = DayMonthYear(this.props.type.includes("checkout") ? this.state.checkout : this.state.checkin) == DayMonthYear(new Date());

        const fake_checkin = CreateTodayByTimestring(this.state.checkout, this.props.booking.property.checkinTime);
        
        let pref_heat = 15;

        const ymdco = new Date(YearMonthDay(this.state.checkout));
        if (ymdco < new Date(ymdco.getFullYear() + '-05-15') || ymdco > new Date(ymdco.getFullYear() + '-09-15'))
            pref_heat = this.state.nextCheckin ? (
                this.props.booking.nextCheckin.calendarSource == 'blocking' ? 18 : ((new Date(this.props.booking.nextCheckin.at) - ymdco) / 24 / 3600 / 1000) > 3 ? 18 : 20
            ) : 18;

        const iconColor = (roleBasedIcon != icon ? "rgba(245, 0, 87, 1)": 
            (this.props.type.includes('checkout') && this.state.cleaned || (this.props.type.includes('checkin') && this.state.cleaned)) ? 
                "rgba(67, 197, 84, 1.0)" : passed && (!this.state?.cleaned  && this.state.started) ?
                    `rgba(197, 17, 98, ${this.state?.started ? "1.0" : "0.5"})` : 
                        "rgba(63, 81, 181, 0.5)"
        );
        
        return <>
            {/* <NBooking {...this.props.booking}> */}
            <ListItem 
                className={this.props.classes.nested}
                onClick={this.toggleOpen}
            >
                <ListItemIcon style={{color: iconColor,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignContent: "center",
                    flexWrap: "wrap",
                    textAlign: "center"
                }}>
                    <AnyComponent component={roleBasedIcon} style={{padding: 0, margin: 0, width: 'auto'}} />
                    <b>{ this.props.type.includes('checkout') ? 
                        (this.state?.started || false) ?
                            moment(this.state?.started || new Date).add(this.state.cleaned ? (this.state.timeUsage || 0) : 0, 'milliseconds').toDate().toTimeString().split(' ')[0].split(':').splice(0, 2).join(':')
                        :
                            this.state.responsible ? 
                                outtime : '—'
                    :
                        moment(this.state.checkin || new Date).toDate().toTimeString().split(' ')[0].split(':').splice(0, 2).join(':')
                    }</b>
                </ListItemIcon>
                <ListItemText primary={<>
                        <b>{ShortAddr(this.props.booking.property.address.address)}</b>
                        <b style={{color: "rgb(245, 0, 87)" /*'rgb(0 134 255)'*/}}> ∷ </b><span style={{color: "rgb(0 0 0 / 64%)"}}>{this.props.booking.property?.section}</span>
                    </>}
                    secondaryTypographyProps={{style: {
                       display: 'flex',
                       alignItems: 'flex-start' 
                    }}}
                    secondary={
                        this.props.type.includes("checkout") && this.props.future ?
                            <>
                                <Login style={{fontSize: '1.3rem', marginRight: '0.5rem' /*, color: iconColor*/}} />
                                <span style={{alignSelf: "center"}}>{ this.state.nextCheckin ? 
                                    same_day ?
                                        `${moment(this.state?.nextCheckin || new Date).from(
                                            this.props.booking?.checkout
                                        ).split(' ').map(s => t(s)).join(' ')} ${t('at')} ${HourMinute(this.state?.nextCheckin)}`
                                    :
                                        t('possible-after-checkout', {
                                            from: moment(fake_checkin).from(this.props.booking.checkout).split(' ').map(s => t(s)).join(' ')
                                        })
                                : 
                                    `${t('possible-after-checkout', {
                                        from: moment(fake_checkin).from(this.props.booking.checkout).split(' ').map(s => t(s)).join(' ')
                                    })}`
                                }</span>
                            </>
                        :
                            <Trans t={t} i18nKey={"guest-responsible"}
                                values={{
                                    guests: this.state.guests, 
                                    responsible: user.users.find(emp => emp['@id'] == this.state.responsible)?.name || "<unknown>"
                                }}
                                components={{bold: <b />}}
                            />
                    }
                />
                {this.state.open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.open} timeout={"auto"} unmountOnExit>
                <List component={"div"}>
                    <ListItem component="div">
                        <Paper className={this.props.classes.paper} style={{width: "100%", marginTop: 0, overflow: 'hidden'}}>
                            <TableContainer>
                                <Table className={this.props.classes.table } sx={{
                                    '& table tr:last-child td': {
                                        borderBottom: 'none',
                                        padding: '8px',
                                    },
                                    '& table tr td': {
                                        display: "inline-grid",
                                    }
                                }}>
                                    <TableBody sx={{
                                        'td': {
                                            // display: 'inline-grid'
                                        }
                                    }}>
                                        <TableRow style={{display: 'none'}}>
                                            {[...Array(8).keys()].map((e, i) => <TableCell key={i}></TableCell>)}
                                        </TableRow>
                                        <BookingIdAndJsonData 
                                            state={this.state} 
                                            user={user} props={this.props} 
                                            booking={this.props.booking} 
                                            property={this.props.booking.property}
                                            locks={[...this.props.booking.property.doorLocks]}
                                        />{
                                            /* RESPONSIBLE  NOT SET */ 
                                        this.props.type.includes("checkout") ? 
                                            (this.state.responsible || false) == false ?
                                            <>
                                                <TableRow style={{backgroundColor: "rgba(245, 0, 87, 0.08)"}}>
                                                    <TableCell colSpan={4}>
                                                        <InputLabel id={`responsible-${this.props.booking['@id']}`} style={{color: '#f50057'}}>
                                                            <b>{t('responsible-cleaner')}:</b>
                                                        </InputLabel>
                                                    </TableCell>
                                                    <TableCell colSpan={4}>{ user.roles.includes("ROLE_MANAGER") ? 
                                                        <FormControl className={this.props.classes.formControl}
                                                            style={{width: '100%'}}
                                                        >
                                                            <Select labelId={`responsible-${this.props.booking['@id']}`}
                                                                id={`responsible-select-${this.props.booking['@id']}`}
                                                                value={this.state.responsible||0}
                                                                onChange={this.handleUpdateResponsible}
                                                                style={{margin: '0 12px'}}
                                                            >
                                                                <MenuItem value={0}>{t('choose-employee')}</MenuItem>
                                                                {user.users.filter(emp => {

                                                                    if (emp.roles.includes('ROLE_ADMIN'))
                                                                        return false;

                                                                    const cmpny = emp.company == user.company || user.roles.includes('ROLE_ADMIN');

                                                                    if (emp.roles.includes('ROLE_TEST'))
                                                                        return user.roles.includes('ROLE_TEST') && cmpny;

                                                                    if (emp.roles.includes('ROLE_DISABLED'))
                                                                        return false;

                                                                    return cmpny;
                                                                }).map(emp => 
                                                                    <MenuItem value={emp['@id']} key={emp.id}>{emp.name}</MenuItem>
                                                                )}
                                                            </Select>
                                                        </FormControl> 
                                                    : <i>{t("not-set")}</i>}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={8} style={{textAlign: 'center'}}>
                                                        <Trans t={t} i18nKey={"warn-update-responsible"}
                                                            components={{bold: <b />}}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                            : /*  RESPONSIBLE CLEANER SET */
                                            <>
                                                <TableRow>
                                                    <TableCell colSpan={8}> 
                                                        <ContactButtonGroup
                                                            key={user.users.find(emp => emp['@id'] == this.state.responsible)?.id}
                                                            user={user.users.find(emp => emp['@id'] == this.state.responsible)}
                                                            requestingUser={user.users.find(emp => emp['@id'] == user['@id'])}
                                                            users={user.users}
                                                            subject={encodeURI(this.props.booking.property.address.address + ", Project: " + this.props.booking.property.section + `\n ${t('checkout')}: ` + DayMonthYear(this.props.booking.checkout))}
                                                            onClickUserHandler={user.roles.includes('ROLE_MANAGER') ? this.handleUpdateResponsible : null}
                                                            roles={user.roles}
                                                            userButtonProps={{
                                                                style: this.state.cleaned ? {color: 'rgba(197, 17, 98, 0.8)'} : {},
                                                                onClick: user.roles.includes('ROLE_MANAGER') ? this.handleUpdateResponsible : null,
                                                                disabled: this.state.cleaned,
                                                                startIcon: <PersonPinCircle />,
                                                                endIcon: user.roles.includes('ROLE_MANAGER') && !this.state.cleaned ? <HighlightOff /> : <></>
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={8}>
                                                        <Table>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell colSpan={4} style={{width: '50%'}}>
                                                                        <StipulatedTimeUsage booking={this.props.booking}
                                                                            responsible={this.state.responsible ?? null}
                                                                            timeUsage={user == this.state.responsible || user.roles.includes('ROLE_ADMIN') ?
                                                                                this.state.timeUsage : this.props.booking.property?.cleaningDuration ?? 0
                                                                            }
                                                                            icon={<PendingActions />}
                                                                            text={user.roles.includes('ROLE_MANAGER') || !this.state.cleaned ? t('stipulated-time') : t('cleaning-duration')}
                                                                            updateHandler={this.handleTimeUsageCleaning}
                                                                            user={user}
                                                                            cleaned={this.state.cleaned ?? false}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell colSpan={4} style={{width: '50%'}}>{ user.roles.includes('ROLE_MANAGER') ? 
                                                                        <StipulatedTimeUsage booking={this.props.booking}
                                                                            responsible={this.state.responsible ?? null}
                                                                            timeUsage={this.state.drivingTimeUsage}
                                                                            icon={<DepartureBoard />}
                                                                            text={t('driving-duration')}
                                                                            updateHandler={this.handleTimeUsageDriving}
                                                                            user={user}
                                                                        /> 
                                                                        : <b style={{color: '#f50057'}}>{t('report-sign-time')}</b>}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                                <ConflictingBookings booking={this.props.booking} user={user} />
                                                <TableRow style={null/*(same_day ? {backgroundColor: "rgba(245, 0, 87, 0.1)"} : {})*/}>
                                                    <TableCell colSpan={8}>
                                                        <Table>
                                                            <TableBody>
                                                                <TableRow sx={{
                                                                    '& .start, & .end': {
                                                                        "& .Mui-disabled *, & .MuiInputBase-root *": {
                                                                            color: "rgba(233, 30, 99, 1) !important",
                                                                            WebkitTextFillColor: "rgba(233, 30, 99, 1) !important",
                                                                            fontWeight: "bolder"
                                                                        },
                                                                        '& .MuiFilledInput-underline': {
                                                                            '&:before': {
                                                                                borderColor: "rgba(233, 30, 99, 0.45) !important", // 3px solid 
                                                                            },
                                                                            '& .MuiInputAdornment-root': {
                                                                                margin: 'auto !important',
                                                                            }
                                                                        },
                                                                        '& label': {
                                                                            color: "rgba(63, 81, 181, 1)",
                                                                            fontWeight: 500,
                                                                        },
                                                                        '& p:last-child': {
                                                                            fontWeight: "500",
                                                                            backgroundColor:" rgba(63, 81, 181, 1)",
                                                                            padding: "0 13px",
                                                                            margin: "0 0px",
                                                                            color: "white"
                                                                        }
                                                                    },
                                                                    '& .start': {
                                                                        '& label': {
                                                                            right: 0,
                                                                            left: 'auto',
                                                                        },
                                                                        '& p:last-child, & .Mui-disabled, & .MuiInputBase-root *': {
                                                                            textAlign: 'right'
                                                                        },                                                                            
                                                                    }, 
                                                                    
                                                                    // '& .start': {
                                                                    //     '
                                                                    // }
                                                                }}>
                                                                    <TableCell colSpan={4} style={{width: '50%'}}>
                                                                        <CustomTimePicker label={`${t('checkout')} ${
                                                                                same_day && HourMinute(this.state.checkout) == HourMinute(this.props.booking.property.checkoutTime) ? t('by') : t('at')
                                                                            }`}
                                                                            date={this.state.checkout}
                                                                            helperText={
                                                                                moment(new Date(this.state.checkout), user, {
                                                                                    relativeTime: {
                                                                                        past: "%s",
                                                                                        futer: "%s"
                                                                                    }
                                                                                }).from(fake_checkin)
                                                                            }
                                                                            handler={this.handleUpdateCheckout}
                                                                            readOnly={!user.roles.includes('ROLE_ADMIN')}
                                                                            variant={"filled"}
                                                                            icon={<AccessAlarm />}
                                                                            inputAdornmentPosition={"start"}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell colSpan={4} style={{width: '50%'}}>
                                                                        <CustomTimePicker label={`${t('checkin')} ${
                                                                                same_day && this.state.nextCheckin ? t('at') : t('from')
                                                                            }`}
                                                                            date={this.state.nextCheckin ?
                                                                                (same_day || user.roles.includes('ROLE_ADMIN') ? this.state.nextCheckin : fake_checkin)
                                                                                : fake_checkin
                                                                            }
                                                                            helperText={ user.roles.includes('ROLE_ADMIN') ? 
                                                                                same_day ? (is_today ? t('today') : t('same-day')) : this.state.nextCheckin ? `${t('on')} ${DayMonthYear(this.state.nextCheckin)}` : t('no-future-booking') :
                                                                                same_day ? (is_today ? t('today') : t('same-day')) : this.state.nextCheckin ? `${t('on')} ${DayMonthYear(this.state.nextCheckin)}` : t('possible')
                                                                            }
                                                                            readOnly={!user.roles.includes('ROLE_ADMIN') || this.state.nextCheckin == null }
                                                                            handler={this.handleUpdateNextCheckin}
                                                                            variant={"filled"}
                                                                            icon={<AlarmOn />}
                                                                            inputAdornmentPosition={"end"}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>{ user.roles.includes('ROLE_TEST') && (this.state.nextCheckin || false) ? 
                                                <UpdateGuestAndRooms booking={this.props.booking}
                                                    guests={this.state?.guests ?? null}
                                                    bedrooms={this.state?.bedrooms ?? null}
                                                    updateGuestHandler={this.handleUpdateGuests}
                                                    updateRoomHandler={this.handleUpdateBedrooms}
                                                    t={t}
                                                /> : <></>}
                                                <TableRow>
                                                    <TableCellHeader colSpan={2}>{t('entry-description')}:</TableCellHeader>
                                                    <TableCell colSpan={6} style={{/*whiteSpace: "pre-wrap"*/}}>
                                                        <StartCleaning user={user}
                                                            booking={this.props.booking}
                                                            t={t}
                                                            started={this.state.started}
                                                            startHandler={this.setTimeStarted}
                                                        />                                                                
                                                    </TableCell>
                                                </TableRow>
                                                { this.props.booking.property?.doorLocks.length > 0 && (this.state.started || user.roles.includes('ROLE_ADMIN')) ?
                                                <TableRow>
                                                    <TableCell colSpan={8}>
                                                        <Table>
                                                            <TableBody>
                                                                <TableRow>{ this.props.booking.property.doorLocks.map((l, i) =>
                                                                    <TableCell key={i}
                                                                        colSpan={(8 / this.props.booking.property.doorLocks.length).toFixed(0)} 
                                                                        style={{width: `${(100 / this.props.booking.property.doorLocks.length).toFixed(0)}%`}}
                                                                    >
                                                                        <LockButton lock={l}
                                                                            visibleFrom={
                                                                                moment(new Date(this.state.checkout) <= new Date(this.props.booking.cleaningDate) ? new Date(this.state.checkout) : new Date(this.props.booking.cleaningDate)).subtract(user.roles.includes('ROLE_MANAGER') ? 15 : 5, 'minutes').toDate()
                                                                            }
                                                                            visibleUntil={
                                                                                moment(new Date(this.props.booking?.nextCheckin?.at ?? fake_checkin)).add(user.roles.includes('ROLE_MANAGER') ? 15 : 5, 'minutes').toDate()
                                                                            }
                                                                            responsible={this.state.responsible}
                                                                            userRoles={user.roles}
                                                                        />
                                                                    </TableCell>)}
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow> : <></>}{
                                                /* CLEANING IS STARTED */
                                                (this.state.started || false) || user.roles.includes('ROLE_ADMIN') ?
                                                    /* CHECK IF CHECKOUT IS PASSED */
                                                    ((new Date(this.state.checkout) <= new Date(this.props.booking.cleaningDate) ? new Date(this.state.checkout) : new Date(this.props.booking.cleaningDate)) - new Date()) < 0 ? 
                                                    <>
                                                        <TableRow role={"rating"}
                                                            tabIndex={-1}
                                                            selected={!this.state.survey.rating && !this.state.cleaned}
                                                        >
                                                            <TableCellHeader colSpan={2}>{t('rating')}:</TableCellHeader>
                                                            <TableCell colSpan={6}>
                                                                <Box component={"div"} style={{width: '100%'}}>
                                                                    {[...Array(5).keys()].map((e, i) => <StarRounded color={"secondary"}
                                                                        key={"rating-" + this.props.booking.id + "-" + (i + 1)}
                                                                        onClick={e => !this.state.cleaned && this.answerSurvey(e, "rating", i + 1)}
                                                                        style={{
                                                                            opacity: i < this.state.survey.rating ? "1.0" : "0.5",
                                                                            cursor: "pointer",
                                                                            margin: '0 9px',
                                                                        }}
                                                                    />)}
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow hover onClick={e => this.answerSurvey(e, "cleaned")}
                                                            role={"checkbox"}
                                                            aria-checked={!this.state.survey.cleaned && !this.state.cleaned}
                                                            tabIndex={-1}
                                                            key={"cleaned"}
                                                            selected={!this.state.survey.cleaned && !this.state.cleaned}
                                                        >
                                                            <TableCell padding={"checkbox"} colSpan={1}>
                                                                <Checkbox checked={this.state.survey.cleaned || this.state.cleaned}
                                                                    disabled={this.state.cleaned}
                                                                    inputProps={{'aria-labelledby': this.props.type + "-cleaned"}}
                                                                />
                                                            </TableCell>
                                                            <TableCell colSpan={7} /*component="th"*/ id={this.props.type + "-cleaned"} padding={"none"}>
                                                                🧹 {t('cleaned')}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow hover onClick={e => this.answerSurvey(e, "guests")}
                                                            role={"checkbox"}
                                                            aria-checked={!this.state.survey.guests && !this.state.cleaned}
                                                            tabIndex={-1}
                                                            key={"guests"}
                                                            selected={!this.state.survey.guests && !this.state.cleaned}
                                                        >
                                                            <TableCell padding={"checkbox"} colSpan={1}>
                                                                <Checkbox checked={this.state.survey.guests || this.state.cleaned}
                                                                    disabled={this.state.cleaned}
                                                                    inputProps={{'aria-labelledby': this.props.type + "-guests"}}
                                                                />
                                                            </TableCell>
                                                            <TableCell colSpan={7} /*component="th"*/ id={this.props.type + "-guests"} padding={"none"}>{(
                                                                    this.props.nextCheckin !== null && this.props.booking.nextCheckin?.guests !== null ||
                                                                    this.props.nextCheckin == null
                                                                ) ? 
                                                                    <GuestRoomsSplit booking={this.props.booking}
                                                                        property={this.props.booking.property}
                                                                    /> : <b>{t('call-req-guests')}</b>
                                                            }</TableCell>
                                                        </TableRow>
                                                        <TableRow hover onClick={e => this.answerSurvey(e, "openings")}
                                                            role={"checkbox"}
                                                            aria-checked={!this.state.survey.openings && !this.state.cleaned}
                                                            tabIndex={-1}
                                                            key={"openings"}
                                                            selected={!this.state.survey.openings && !this.state.cleaned}
                                                        >
                                                            <TableCell padding={"checkbox"} colSpan={1}>
                                                                <Checkbox checked={this.state.survey.openings || this.state.cleaned}
                                                                    disabled={this.state.cleaned}
                                                                    inputProps={{'aria-labelledby': this.props.type + "-openings"}}
                                                                />
                                                            </TableCell>
                                                            <TableCell colSpan={7} /*component="th"*/ id={this.props.type + "-openings"} padding={"none"}>
                                                                🔐 <Trans t={t} i18nKey={"closed-openings"}
                                                                    components={{bold: <b />}}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow hover onClick={e => this.answerSurvey(e, "heating")}
                                                            role={"checkbox"}
                                                            aria-checked={!this.state.survey.heating && !this.state.cleaned}
                                                            tabIndex={-1}
                                                            key={"heating"}
                                                            selected={!this.state.survey.heating && !this.state.cleaned}
                                                        >
                                                            <TableCell padding={"checkbox"} colSpan={1}>
                                                                <Checkbox checked={this.state.survey.heating || this.state.cleaned}
                                                                    disabled={this.state.cleaned}
                                                                    inputProps={{'aria-labelledby': this.props.type + "-heating"}}
                                                                />
                                                            </TableCell>
                                                            <TableCell colSpan={7} /*component="th"*/ id={this.props.type + "-heating"} padding={"none"}>
                                                                🌡 <Trans t={t} i18nKey={"adjusted-temp"}
                                                                    components={{bold: <b />}}
                                                                    values={{pref_heat}}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow hover onClick={e => this.answerSurvey(e, "curtains")}
                                                            role={"checkbox"}
                                                            aria-checked={!this.state.survey.curtains && !this.state.cleaned}
                                                            tabIndex={-1}
                                                            key={"curtains"}
                                                            selected={!this.state.survey.curtains && !this.state.cleaned}
                                                        >
                                                            <TableCell padding={"checkbox"} colSpan={1}>
                                                                <Checkbox checked={this.state.survey.curtains || this.state.cleaned}
                                                                    disabled={this.state.cleaned}
                                                                    inputProps={{'aria-labelledby': this.props.type + "-curtains"}}
                                                                />
                                                            </TableCell>
                                                            <TableCell colSpan={7} /*component="th"*/ id={this.props.type + "-curtains"} padding={"none"}>
                                                                🪟 <Trans t={t} i18nKey={"open-blinds"}
                                                                    components={{bold: <b />}}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={8}>{ this.state.cleaned ?        
                                                                <Alert severity={
                                                                    !((this.state.survey?.markedNotes || [])[this.props.booking['@id']] || false) && ((this.state.notes || [])[this.props.booking['@id']] || false) ?
                                                                        "error" : "success" 
                                                                }>{ this.props.booking?.employeeNote ? <>
                                                                    <AlertTitle>{t("note-after-maintenance")}</AlertTitle>
                                                                    <TranslateText
                                                                            origText={this.props.booking?.employeeNote}
                                                                            srcLang={user.users.find(emp => emp["@id"] == this.state.responsible).language}
                                                                            dstLang={user.language}
                                                                        />
                                                                    </> : t("no-remarks-after-maintenance")}
                                                                </Alert> 
                                                            :
                                                                <TextField multiline
                                                                    defaultValue={this.state.survey.note}
                                                                    onChange={e => this.answerSurvey(e, "note")}
                                                                    maxRows={6}
                                                                    minRows={3}
                                                                    disabled={this.state.cleaned}
                                                                    label={t('notes-desc')}
                                                                    placeholder={t('notes-desc-extra')}
                                                                    style={{width: "100%"}}
                                                                    color="secondary"
                                                                />}
                                                            </TableCell>
                                                        </TableRow>
                                                        <BookingNotes
                                                            booking={this.props.booking} 
                                                            notes={this.state.notes} 
                                                            user={user} 
                                                            markedNotes={this.state.survey.markedNotes} 
                                                            handleNote={this.checkNote}
                                                        />
                                                        <MarkReadyButton survey={this.state.survey} 
                                                            responsible={this.state.responsible} 
                                                            user={user} 
                                                            handleMarkReady={this.markReady}
                                                            clean={this.state.cleaned}
                                                        />
                                                        </> 
                                                        : /* WAITING FOR CHECKOUT TIME TO BE PASSED */ 
                                                        <>
                                                        <TableRow>
                                                            <TableCellHeader colSpan={2}>
                                                                Amount of guests
                                                            </TableCellHeader>
                                                            <TableCell>
                                                                <GuestRoomsSplit
                                                                    booking={this.props.booking}
                                                                    property={this.props.booking.property}
                                                                    guests={this.state?.guests}
                                                                    bedrooms={this.state?.bedrooms}
                                                                />
                                                            </TableCell>
                                                        </TableRow>{ is_today ? 
                                                        <TableRow>
                                                            <TableCell colSpan={8}>
                                                                <Alert severity="info">{t('wait-for-checkout-pass')}</Alert>
                                                            </TableCell>
                                                        </TableRow>
                                                        : <></>}
                                                    </>
                                                    : /* CLEANING IS NOT STARTED */
                                                    <TableRow style={((this.state.guests || false) === false ? {backgroundColor: "rgba(245, 0, 87, 0.08)"} : {})}>{
                                                        /* LOOK FOR FUTURE CHECKIN */
                                                        (this.props.booking?.nextCheckin || false) ?
                                                    <>
                                                        <TableCellHeader colSpan={2}>{t('amount-guests')}</TableCellHeader>
                                                        <TableCell colSpan={6}>{ 
                                                            /* GUEST AMOUNT IS PRESENT */
                                                            this.state.guests === null || user.roles.includes('ROLE_ADMIN') ? 
                                                            <Button
                                                                style={{
                                                                    width: "100%",
                                                                    padding: 0,
                                                                }}
                                                                color={"secondary"}
                                                                disabled={this.state.guestsRequested}
                                                                onClick={() => this.handleRequestGuestAmount()}
                                                            >
                                                                {this.state.guestsRequested ? `${t('sent')}!` : t('req-guests')}
                                                            </Button>
                                                            :
                                                            <GuestRoomsSplit
                                                                 booking={this.props.booking}
                                                                 property={this.props.booking.property}
                                                                 guests={this.state?.guests}
                                                                 bedrooms={this.state?.bedrooms}
                                                             />
                                                        }</TableCell>
                                                    </> : <>
                                                        <TableCellHeader colSpan={2}>{t('amount-guests')}</TableCellHeader>
                                                        <TableCell colSpan={6}>
                                                            <GuestRoomsSplit
                                                                booking={this.props.booking}
                                                                property={this.props.booking.property}
                                                                guests={this.state?.guests}
                                                                bedrooms={this.state?.guests}
                                                            />
                                                        </TableCell>
                                                    </>}
                                                    </TableRow>
                                                }
                                            </>

                                        : /* CHECKIN VIEW */
                                        <>
                                            { this.state.previousCheckout && this.state.responsible ? 
                                            <TableRow>
                                                <TableCell colSpan={8}> 
                                                    <ContactButtonGroup
                                                        key={user.users.find(emp => emp['@id'] == this.state.responsible).id || this.booking['@id']}
                                                        user={user.users.find(emp => emp['@id'] == this.state.responsible)}
                                                        requestingUser={user.users.find(emp => emp['@id'] == user['@id'])}
                                                        users={user.users}
                                                        subject={encodeURI(this.props.booking.property.address.address + ", Project: " + this.props.booking.property.section + `\n ${t('checkout')}: ` + DayMonthYear(this.state.previousCheckout))}
                                                        onClickUserHandler={null}
                                                        roles={user.roles}
                                                        userButtonProps={{
                                                            style: this.state.cleaned ? {color: 'rgba(197, 17, 98, 0.8)'} : {},
                                                            onClick: user.roles.includes('ROLE_MANAGER') ? this.handleUpdateResponsible : null,
                                                            disabled: this.state.cleaned,
                                                            startIcon: <PersonPinCircle />,
                                                            endIcon: user.roles.includes('ROLE_MANAGER') && !this.state.cleaned ? <HighlightOff /> : <></>
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow> : <></>}
                                            <TableRow style={(same_day ? {backgroundColor: "rgba(245, 0, 87, 0.1)"} : {})}>
                                                <TableCell colSpan={8}>
                                                    <Table>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell colSpan={4} style={{width: '50%'}}>
                                                                    <CustomTimePicker label={t('next-checkin')}
                                                                        date={this.state.checkin}
                                                                        helperText={this.props.booking.calendarSource}
                                                                        readOnly={!user.roles.includes('ROLE_ADMIN')}
                                                                        handler={this.handleUpdateCheckin}
                                                                        variant={"filled"}
                                                                    />
                                                                </TableCell>
                                                                <TableCell colSpan={4} style={{width: '50%'}}>
                                                                    <CustomTimePicker label={
                                                                            `${t('checkout')} ${
                                                                                HourMinute(this.state.checkout) == HourMinute(this.props.booking.property.checkoutTime) ? t('until') : t('at')
                                                                            }`
                                                                        }
                                                                        date={this.state.checkout}
                                                                        helperText={DayMonthYear(this.state.checkout)}
                                                                        handler={this.handleUpdateCheckout}
                                                                        readOnly={!user.roles.includes('ROLE_ADMIN')}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                            <ConflictingBookings booking={this.props.booking} user={user} />
                                            <UpdateGuestAndRooms booking={this.props.booking}
                                                guests={this.state?.guests ?? null} 
                                                bedrooms={this.state?.bedrooms ?? null} 
                                                updateGuestHandler={this.handleUpdateGuests} 
                                                updateRoomHandler={this.handleUpdateBedrooms}
                                                t={t}
                                                checkin
                                            />
                                        </>
                                    }</TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </ListItem>
                </List>
                <Divider />
            </Collapse>
        {/* </NBooking> */}
        </>
    }
}
                                
                                
                                        
                                // </> 
                                // : 
                                // <>
                                //     <TableRow>
                                //         <TableCell colSpan={1}>ID:</TableCell>
                                //         <TableCell colSpan={3}>{this.props.booking['@id']}</TableCell>
                                //     </TableRow>
                                //     {/* <!--- can go here --!> */}
                                //     <BookingNotes 
                                //         user={user} 
                                //         booking={this.props.booking} 
                                //         notes={this.state.notes} 
                                //         markedNotes={this.state.survey.markedNotes} 
                                //         handleNote={this.checkNote}
                                //     />
                                //     { this.state.previousCheckout ?
                                //         <>
                                //             {this.props.booking.previousCheckout.responsible ? 
                                //                 <TableRow style={(!this.props.booking.previousCheckout.responsible ? {backgroundColor: "rgba(245, 0, 87, 0.08)"} : {})}>
                                //                     <TableCell colSpan={4}>
                                //                         <ContactButtonGroup
                                //                             user={user.users.find(emp => emp['@id'] == this.props.booking.previousCheckout.responsible['@id'])}
                                //                             requestingUser={user.users.find(emp => emp['@id'] == user.id)}
                                //                             users={user.users}
                                //                             subject={encodeURI(this.props.booking.property.address.address + ", Project: " + this.props.booking.property.section + " \nCheckout: " + DayMonthYear(this.props.booking.checkout))}
                                //                             roles={user.roles}
                                //                             userButtonProps={{
                                //                                 startIcon: <PersonPinCircle />
                                //                             }}
                                //                         />
                                //                     </TableCell>
                                //                 </TableRow>
                                //             :
                                //                 <></>
                                //             }
                                //             <TableRow color={"secondary"}>
                                //                 <TableCell colSpan={1} style={{backgroundColor: "rgba(245, 0, 87, 0.08)"}}>
                                //                     Previous checkout:
                                //                 </TableCell>
                                //                 <TableCell colSpan={2} style={{backgroundColor: "rgba(245, 0, 87, 0.08)"}}>
                                //                     <CustomTimePicker
                                //                         date={this.state.previousCheckout}
                                //                         helperText={MomentUtils(this.state.previousCheckout).from(this.state.checkin)}
                                //                         label={
                                //                             <>
                                //                                 {DayMonthYear(this.props.booking?.previousCheckout?.begin)} - {DayMonthYear(this.state.previousCheckout)}
                                //                             </>
                                //                         }
                                //                         handler={this.handleUpdatePrevCheckout}
                                //                     />
                                //                 </TableCell>
                                //                 <TableCell style={{backgroundColor: "rgba(245, 0, 87, 0.08)"}}>
                                //                     <Button startIcon={<Hotel />}>
                                //                         {this.props.booking.previousCheckout.guests} guests
                                //                     </Button>
                                //                 </TableCell>
                                //             </TableRow>
                                //         </>
                                //     :  
                                //         <>
                                //             <TableRow>
                                //                 <TableCell colSpan={2}>
                                //                     <CustomTimePicker date={this.state.checkin}
                                //                         label={"Check-in"}
                                //                         helperText={ this.state.previousCheckout ?
                                //                             `${MomentUtils(this.state.checkin).from(this.state.previousCheckout)} previos check-out` :
                                //                                 is_today ? 'today' : `in ${MomentUtils(this.state.checkin).from(new Date())} today`
                                //                         }
                                //                         handler={this.handleUpdateCheckin}
                                //                     />
                                //                 </TableCell>
                                //                 <TableCell colSpan={2}>
                                //                     <CustomTimePicker date={this.state.checkout}
                                //                         label={"Check-out"}
                                //                         helperText={
                                //                             <span>
                                //                                 <span>{MomentUtils(this.state.checkout).from(this.state.checkin)} on </span>
                                //                                 <b> {DayMonthYear(this.state.checkout)}</b>
                                //                             </span>
                                //                         }
                                //                         handler={this.handleUpdateCheckout}
                                //                     />
                                //                 </TableCell>
                                //             </TableRow>
                                //             <TableRow>
                                //                 <TableCell colSpan={2}>
                                //                 { user.roles.includes("ROLE_ADMIN") ? 
                                //                     <TextField id="update-guests" 
                                //                         label="Guests" 
                                //                         defaultValue={this.state.guests}
                                //                         error={this.state.guests === null}
                                //                         helperText={this.state.guests === null ? `Update guests from «${this.props.booking.calendarSource}»` : `From ${this.props.booking.calendarSource}`}
                                //                         onChange={e => this.handleUpdateGuests(e)}
                                //                         InputProps={{
                                //                             endAdornment: (
                                //                                 <InputAdornment position="start" style={{color: 'gray'}}><Hotel /></InputAdornment>
                                //                             )
                                //                         }}
                                //                     />
                                //                 : 
                                //                     this.props.booking.guests ? this.props.booking.guests : 0
                                //                 }
                                //                 </TableCell>
                                //                 <TableCell colSpan={2}>
                                //                 { user.roles.includes("ROLE_ADMIN") ? 
                                //                     <TextField id="update-bedrooms" 
                                //                         label="Bedrooms" 
                                //                         defaultValue={this.state.bedrooms}
                                //                         error={this.state.bedrooms === null}
                                //                         helperText={`Max ${this.props.booking.property.numBedrooms}`}
                                //                         onChange={e => this.handleUpdateBedrooms(e)}
                                //                         InputProps={{
                                //                             endAdornment: (
                                //                                 <InputAdornment position="start" style={{color: 'gray'}}><NightShelter /></InputAdornment>
                                //                             ),
                                //                         }}
                                //                     />
                                //                 : 
                                //                     this.props.booking?.bedrooms || 0
                                //                 }
                                //                 </TableCell>
                                //             </TableRow>
                                //             </>
                                //         }

const BookingIdAndJsonData = ({user, booking, ...props}) => {

    const jsonData = (state, {name, data}) => ({
        ...state,
        [name]: data,
        curr: state.curr || name,
    });

    const [open, setOpen] = useState(false);
    const [json, setJson] = useState(
        Object.keys(props).map(k => ({name: k, data: props[k]})).reduce((s, kvp) => jsonData(s, kvp), {})
    );
    const url = booking['@id'];

    const jTypes = Object.keys(json).filter(n => json[n] && n != 'curr');
    const nType = (n) => jTypes.at((jTypes.indexOf(json.curr) + n) % jTypes.length)

    const switchJson = (n) => {
        setJson(state => ({
            ...state,
            curr: nType(n || 1)
        }));
    }

    useEffect(() => {
        if (!open) return;
        axios.get(url)
            .then(r => setJson(state => jsonData(state, {name: 'json', data: r.data})))
            .catch(e => setJson(state => jsonDate({...state, curr: 'error'}, {name: 'error', data: e.response})));
    }, [open]);

    /* SHOW THE BOOKING ID to ADMIN*/ 
    if (!user.roles.includes('ROLE_TEST'))
        return;

    const edit = (o, e) => {
        if ((e?.namespace ?? []).length > 0)
            return;
        console.log(o['@id'], e);
    }

    return <TableRow>
        <TableCell colSpan={8} style={{padding: 0}}>
            <TableContainer>
                <Table className={props?.classes?.table} sx={{
                    '& tr td': {
                        display: 'table-cell !important'
                    }
                }}>
                    <TableBody>
                        <TableRow>                
                            <TableCellHeader colSpan={1} style={{backgroundColor: "rgba(63, 81, 181, 1)", padding: "7px 0 7px 10px", color: 'white'}}>ID:</TableCellHeader>
                            <TableCell colSpan={booking?.cancelled ? 5 : 6} style={{backgroundColor: "rgba(63, 81, 181, 1)", padding: "7px 0", color: "white !important"}}>
                                <a href={url + '.jsonld'} 
                                    target="_blank" 
                                    onClick={(e) => {
                                        setOpen(true);
                                        e.preventDefault();
                                    }}
                                >
                                    {booking.id}
                                </a> (<a href={url} target="_blank">api</a>)
                            </TableCell>
                            <TableCell colSpan={booking?.cancelled ? 2 : 1} style={booking?.cancelled ? {
                                backgroundColor: "rgba(245, 0, 87, 1)",
                                fontWeight: "bolder",
                                textAlign: "center",
                                color: "white",
                                textTransform: "uppercase",
                                padding: ""
                            } : {backgroundColor: "rgba(63, 81, 181, 1)", padding: "7px 0"}}>{booking?.cancelled ? 'Cancelled!' : ''}
                                <Modal open={open}
                                    onClose={() => setOpen(false)}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Card sx={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        position: 'absolute',
                                        top: '50%', left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '90%', maxHeight: '90%',
                                        bgcolor: 'background.paper',
                                        border: '2px solid #000',
                                        boxShadow: 24, //p: 4,
                                    }}>
                                        <CardContent sx={{
                                            display: 'flex',
                                            overflow: 'auto'
                                        }}>
                                            <ReactJson src={json[json.curr]} onEdit={json.curr.includes('json') ? (e) => edit(json[json.curr], e) : false} name={json.curr.toUpperCase()} collapsed={1} />
                                        </CardContent>
                                        <CardActions sx={{
                                            display: 'flex'
                                        }}>
                                            <ButtonGroup 
                                                style={{width: '100%'}} 
                                            >
                                                <Button onClick={() => setOpen(false)}
                                                    variant="outlined"
                                                    size={"medium"} color="primary"
                                                    style={{width: '100%'}}
                                                    startIcon={<Close />}
                                                >Close</Button>
                                                <Button onClick={
                                                        () => switchJson(-1)
                                                    }
                                                    variant="outlined"
                                                    size={"medium"} color="secondary"
                                                    style={{width: '100%'}}
                                                    startIcon={
                                                        <ArrowBackIos />
                                                    }
                                                >{nType(-1)}</Button>
                                                <Button onClick={
                                                        () => switchJson(1)
                                                    }
                                                    variant="outlined"
                                                    size={"medium"} color="secondary"
                                                    style={{width: '100%'}}
                                                    endIcon={
                                                        <ArrowForwardIos />
                                                    }
                                                >{nType(1)}</Button>
                                                <Button component={Link}
                                                    href={url} target="_blank"
                                                    variant="outlined"
                                                    size={"medium"} color="primary"
                                                    style={{
                                                        width: '100%',
                                                        textDecoration: 'none',
                                                        //color: 'white',
                                                        fontWeight: 'bold'
                                                    }}
                                                    endIcon={<OpenInBrowser />}
                                                >Visit</Button>
                                            </ButtonGroup>
                                        </CardActions>
                                    </Card>
                                </Modal>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </TableCell>
    </TableRow>
}

const ConflictingBookings = ({booking, user}) => {

    if ((booking?.conflictingBookings || []).length == 0 || !user.roles.includes('ROLE_ADMIN'))
        return <></>

    const style = {
        background: 'white',
        width: '40px',
        height: '40px',
        borderRadius: '27%',
        textAlign: 'center',
        verticalAlign: 'middle',
        padding: '6px',
        margin: '-10px -5px'
    };

    return booking.conflictingBookings.map((b, i) =>
        <TableRow style={{backgroundColor: pink[i % 2 == 0 ? 100 : 200]}} key={booking['@id'] + b['@id'].split('/').slice(-1)}>
            <TableCell colSpan={1}>{
                b.calendarSource == 'Airbnb' ? <AirbnbIcon style={style} /> : 
                b.calendarSource == 'Booking' ? <BookingIcon style={style} /> :
                b.calendarSource == 'Google' ? <GoogleIcon style={style} /> : 
                <GVIcon style={style} />
            }</TableCell>
            <TableCell style={{color: "#3f51b5"}} colSpan={1}>#{b['@id'].split('/').slice(-1)}</TableCell>
            <TableCell style={{color: "#3f51b5"}} colSpan={2}>{new Date(b.checkout) < new Date() ? 'Past' : new Date(b.checkin) < new Date() ? 'Staying' : 'Upcoming'}</TableCell>
            <TableCell style={{color: "#3f51b5", fontWeight: 'bolder'}} colSpan={2}>{DayMonthYear(b.checkin)}</TableCell>
            <TableCell style={{color: "#3f51b5", fontWeight: 'bold'}} colSpan={2}>{DayMonthYear(b.checkout)}</TableCell>
        </TableRow>
    )
}

const UpdateGuestAndRooms = ({booking, guests, bedrooms, t, updateGuestHandler, updateRoomHandler, checkin}) => {
    return <TableRow>
        <TableCell colSpan={8}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={4} style={{width: '50%'}}>
                            <TextField id="update-guests" 
                                label={t('guests')}
                                defaultValue={guests ?? booking.nextCheckin?.guests ?? null}
                                error={guests === null}
                                helperText={guests === null ? `Update from «${(checkin ? booking?.calendarSource : booking.nextCheckin)?.calendarSource || '??'}»` : `From ${checkin ? booking?.calendarSource : booking.nextCheckin?.calendarSource || '??'}`}
                                onChange={e => updateGuestHandler(e, 'nextCheckin')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start" style={{color: 'gray'}}>
                                            <Hotel />
                                        </InputAdornment>
                                    )
                                }}
                                variant="filled"
                            />
                        </TableCell>
                        <TableCell colSpan={4} style={{width: '50%'}}>
                            <TextField id="update-bedrooms" 
                                label={t('bedrooms')}
                                defaultValue={bedrooms ?? booking.nextCheckin?.bedrooms ?? null}
                                error={bedrooms === null}
                                helperText={`Max ${booking.property?.numBedrooms}`}
                                onChange={e => updateRoomHandler(e, 'nextCheckin')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start" style={{color: 'gray'}}>
                                            <NightShelter />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="filled"
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableCell>
    </TableRow>
}

const StartCleaning = ({booking, user, t, started, startHandler}) => {

    const min = user.roles.includes('ROLE_MANAGER') ? 15 : 5;

    const [err, setError] = useState(false);
    const [time, setTime] = useState(moment(booking.checkout).subtract(min, 'minutes'));

    const [open, setOpen] = useState(
        started || 
        (user.roles.includes('ROLE_MANAGER') && new Date >= time.toDate()) || 
        user.roles.includes('ROLE_ADMIN')
    );

    const setStart = () => {
        axios.put(`${booking['@id']}`, {
            startTime: true
        }).then(resp => {
            startHandler(resp.data.timeStart);
            setOpen(true);
        }).catch(err => {
            setError(err)
        });
    }

    const startButton = <Button
        disabled={new Date < time.toDate()}
        color="secondary"
        variant="contained"
        onClick={setStart}
        endIcon={<Preview />}
    >
        {new Date < time.toDate() ? 
            `${t('available')} ${time.fromNow()}` : 
                user.roles.includes('ROLE_MANAGER') ? 
                    t('start-cleaning-task') : t('open-description')
        }
    </Button>

    return open || user.roles.includes('ROLE_ADMIN') ?
        <>
            
            <TranslateText component={<p />}
                dstLang={user.language}
                origText={booking.property.entryDescription}
                srcLang={'en'}
            />{ !started && user.roles.includes('ROLE_MANAGER') && !user.roles.includes('ROLE_ADMIN') ?
            <>
                <Trans t={t} i18nKey={"do-not-share-with-responsible"}
                    components={{ 
                        boldStyled: <b style={{color: "#f50057"}} />,
                        bold: <b />
                    }}
                    values={{name: booking.responsible?.name}}
                />
                <div>
                    {startButton}
                </div>
            </> : <></>  
            }
        </>
    : <>
        <p hidden={new Date >= time.toDate()}>
            <Trans t={t} i18nKey={"entry-when"} values={{in: min}}
                components={{
                    bold: <b />
                }}
            />
        </p>
        <div>
            {startButton}
        </div>
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            open={err != false}
            autoHideDuration={8000}
            onClose={() => setError(false)}
            message={
                <span>
                    <b style={{color: '#f50057'}}>
                        <TranslateText
                            origText={err?.response?.data['hydra:title']}
                            srcLang={'en'}
                            dstLang={user.language}
                        />
                    </b>
                    <br /> <TranslateText
                        origText={err?.response?.data['hydra:description']}
                        srcLang={'en'}
                        dstLang={user.language}
                    />
                </span>
            }
            action={
                <Button color="secondary" size="small" onClick={() => setError(false)}
                    endIcon={<ReportProblem />}
                >
                    {t('close-and-end-other-tasks')} 
                </Button>
            } 
        />
    </>
}

const WaitDots = ({text, waiting}) => {
    const max = 3;
    const [dots, setDots] = useState(1);

    const incDots = () =>  {
        setDots(state => (state + 1) % (max + 1))
    };

    useEffect(() => {
        if (!waiting)
            return () => {};

        const timer = setTimeout(incDots, 1000);
        return () => clearTimeout(timer);
    }, [dots]);

    if (!waiting)
        return <>{text}</>;

    return <>{text}{".".repeat(dots)}{[...Array(max - dots).keys()].map(i => <React.Fragment key={i}>&nbsp;</React.Fragment>)}</>;
}

const StyledBadge = styled(Badge)(({theme, hsl}) => {
    return {
    '& .MuiBadge-badge': {
        left: 6,
        right: 0,
        border: `1px solid ${theme.palette.background.paper}`,
        padding: '10px 10px 10px 12px',
        color: hsl,
        backgroundColor: 'white',
        borderColor: hsl
    },
}});

const BatteryIcon = ({level}) => {

    if ((level ?? false) == false)
        return <BatteryUnknown color={"info"} />;

    const batteryLevelUnknown = parseInt(`${(level ?? 0)}`);
    const batteryLevel = (batteryLevelUnknown < 0 || batteryLevelUnknown > 100) ? (batteryLevelUnknown > 100 ? 100 : 0) : batteryLevelUnknown;
    const batteryIdx = parseInt((batteryLevel * (8/100)) - 0.1);

    const hue = 147 / 100 * batteryLevel;
    const sat = 50.0 + (50.0 - ((hue - 39) / (108/100) / 2));

    const stl = {
        color: `hsl(${(hue >= 0.0 ? hue : 0.0).toFixed(0)}, ${(sat <= 100.0 ? sat : 100.0).toFixed(0)}%, 50%)`
    }

    const ColoredBatteryIcons = ({lvl, ...props}) => {
        return [
            <BatteryAlert {...props} />,
            <Battery20 {...props} />,
            <Battery30 {...props} />,
            <Battery50 {...props} />,
            <Battery60 {...props} />,
            <Battery80 {...props} />,
            <Battery90  {...props} />,
            <BatteryFull {...props} />
        ][lvl];
    };

    return <StyledBadge badgeContent={batteryLevel} hsl={stl.color}>
        <ColoredBatteryIcons lvl={batteryIdx} style={stl} />
    </StyledBadge>
}

const ButtonGroupIconButton = ({ disableElevation, fullWidth, ...iconButtonProps }) => {
    return <IconButton {...iconButtonProps} />;
}

const LockButton = ({lock, visibleFrom, visibleUntil, userRoles, responsible}) => {

    const testVisible = (isUnlocked) => isUnlocked || (visibleFrom <= new Date() && (
        userRoles.includes('ROLE_MANAGER') && DayMonthYear(visibleUntil) == DayMonthYear(new Date()) || visibleUntil >= new Date()
    ));

    const [locked, setLocked] = useState(lock.locked);
    const [refresh, setRefresh] = useState(null);
    const [loading, setLoading] = useState(false);
    const [failed, setFailed] = useState(false);
    const [visible, setVisible] = useState(testVisible(lock?.locked == false));
    const {t} = useTranslation('bookings');

    const toggleLock = () => {
        if (!visible || loading)
            return ;

        setLoading(true);
        axios.get(
            `/api/door/${locked ? 'unlock' : 'lock'}/${lock.id}`
        ).then(resp => {
            setLocked(() => {
                if (failed) setFailed(false);
                if (testVisible(false) == false && !visible)
                    setVisible(testVisible(resp.data?.locked == false));
                return resp.data.locked
            })
        }).catch(() => setFailed(true)).finally(() => setLoading(false));
    }

    const checkStatus = (timer) => {
        setRefresh(true);
        axios.get(`/api/door/status/${lock.id}`).then(resp => {
            setLocked(() => {
                setFailed(false);
                setVisible(testVisible(resp.data?.locked == false));
                return resp.data.locked
            });
        }).catch(() => {
            setFailed(true);
        }).finally(() => {
            setRefresh(false);
            clearTimeout(timer);
        });
    };

    useEffect(() => {
        
        if (loading || refresh) 
            return () => {};

        const timer = setTimeout(() => checkStatus(timer), refresh === null ? 0 : (visible && !loading ? 10000 : 15000))
        return () => clearTimeout(timer);
    }, [refresh, loading]);

    const lockText = !visible ? (locked ? t('Locked') : t('Unlocked')) : (
        loading ? (locked ? t('Unlocking') : t('Locking')) :
        t(lock.name) //`${locked ? t("Unlock") : t("Lock")} ${t(lock.name)}`
    );

    return <ButtonGroup color="secondary" aria-label={"outlined primary button group"} variant={failed ? "outlined" : "contained"} style={{width: "100%"}}>
        <Button onClick={() => toggleLock()}
            color={locked ? "primary" : "secondary"}
            style={{width: '100%'}}
            disabled={loading || !visible}
            startIcon={!visible ? <LockClock /> : (
                loading ? <SyncLock /> : locked ? 
                    <Lock /> : <LockOpen />
            )}
        >
            <WaitDots text={lockText} waiting={loading || !visible} />
        </Button>
        <Button variant="outlined" color={locked ? "primary" : "secondary"} style={{background: `rgba(${ locked ? '63,81,181' : '245,0,87' }, 0.1)`}} onClick={() => toggleLock()}>{failed ? 
            <SyncProblem color="error" /> :
            <BatteryIcon level={lock.batteryLevel} />}
        </Button>
    </ButtonGroup>
}

const BookingNotes = ({booking, notes, user, markedNotes, handleNote}) => {

    if (!user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_MANAGER'))
        return <></>;

    const [open, setOpen] = useState(false);
    const {t} = useTranslation('bookings');

    return Object.keys(notes || {}).filter(n => user.roles.includes('ROLE_ADMIN') || n == booking['@id']).map(n => 
        <TableRow 
            onClick={(e) => setOpen(!open)}
            key={booking['@id'] + "-note-" + n.replace('/', '-')}
            role={"checkbox"}
            aria-checked={booking['@id'] + '-note-' + n.replace("/'", '-')}
            tabIndex={-1}
            style={{disabled: n in notes, cursor: "pointer", backgroundColor: "rgba(245, 0, 87, 0.08)"}}
        >
            <TableCell colSpan={1} padding="checkbox">
                <Checkbox 
                    checked={n in markedNotes ? markedNotes[n] : false}
                    inputProps={{'aria-labelledby': booking['@id'] + '-note-' + n.replace('/', '-')}}
                    style={{disabled: n in notes}}
                />
            </TableCell>
            <TableCell colSpan={7} component="th" id={booking['@id'] + '-note-' + n.replace('/', '-')} padding={"none"}>
                <span style={{textDecoration:  (n in notes ? "none" : "line-through"), display: 'block', padding: "10px 5px 10px 0"}}>
                    <TranslateText
                        origText={notes[n]}
                        srcLang={booking.responsible?.language}
                        dstLang={user.language}
                        off={true}
                    />
                </span>
                <Dialog
                    open={open}
                    aria-labelledby={booking['@id'] + "-alert-title-" + n.replace("/", "-")}
                    aria-describedby={booking['@id'] + '-alert-desc-' + n.replace("/", "-")}
                >
                    <DialogTitle id={booking['@id'] + "-alert-title" + n.replace("/", '-')}></DialogTitle>
                    <DialogContentText id={booking['@id'] + "-alert-desc" + n.replace("/", "-")} component={"div"}>
                        <p style={{padding: "15px", display: "block"}}>
                            {t('confirm-task-resolved')}
                        </p>
                        <ul>
                            <li>
                                <TranslateText
                                    origText={notes[n]}
                                    srcLang={booking.responsible?.language}
                                    dstLang={user.language}
                                />
                            </li>
                        </ul>
                        <p style={{padding: "20px 15px"}}>{<Trans t={t} i18nKey="dont-remove-unresolved-tasks"
                            components={{bold: <b style={{color: "#f50057"}} />}}
                        />}</p>
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} color={"primary"}>
                            {t('cancel')}
                        </Button>
                        <Button onClick={() => handleNote(true, n)} color={"secondary"}>
                            {t('confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </TableCell>
        </TableRow>
    )
}

const cacheProvider = {
    get: (language, key) => ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[
        language
    ],
    set: (language, key, value) => {
        const existing = JSON.parse(localStorage.getItem('translations')) || {
            [key]: {},
        };
        existing[key] = {...existing[key], [language]: value};
        localStorage.setItem('translations', JSON.stringify(existing));
    },
};

const TranslateText = ({origText, dstLang, srcLang, off, component, ...componentProps}) => {
    
    const dLang = dstLang || 'en';
    const sLang = srcLang || 'en';
    const [lang, setLang] = useState(off ? sLang : dLang);
    const [text, setText] = useState(origText);
    const [loading, setLoading] = useState(!lang.includes(srcLang));

    const {t} = useTranslation('bookings');

    if (sLang == dLang)
        return <AnyComponent component={component} {...componentProps}>{text}</AnyComponent>;

    const translate = async () => {
        const trText = cacheProvider.get(lang, text);

        if (trText) {
            setLoading(false);
            setText(trText);
            return;
        }

        axios.post(
            'https://translation.googleapis.com/language/translate/v2?key=AIzaSyCggLZddryCdHqRW7xabPDIczNYWrSWAkc', {
                q: origText.replace(/(\r\n|\n|\r)/gm, "<br />"), //encode ? encode(text) : text,
                target: lang,
                sourceLanguageCode: srcLang
            }
        ).then(resp => {
            setText(() => {
                const trans = resp.data.data.translations.map(t => {
                    return t.translatedText.replace(/<\s*\/?br\s*[\/]?>/gi, "\n")
                        .replace(/&(#?[a-z0-9]{1,4});/gmi, match => new DOMParser()
                            .parseFromString(match, "text/html").documentElement.textContent
                        )
                }).join(',');
                if (resp.data.data.translations.length == 1 && resp.data.data.translations[0].detectedSourceLanguage == dLang) {
                    setLang(dLang);
                    setLoading(false);
                    setText(trans);
                    return text;
                }
                cacheProvider.set(lang, origText, trans);
                setLoading(false);
                return trans;
            });
        }).catch(err => {
            setLoading(false);
            //setText(<>{origText}<br /><b>{t('error')}!</b> {err?.response?.data?.error?.message}</>)
        })
    };

    useEffect(() => {
        if (lang.includes(srcLang)) {
            setText(origText);
            return;
        }
        translate();
    }, [lang])

    return <>
        <AnyComponent component={component} {...componentProps}>{text}</AnyComponent> 
        <Chip
            icon={<GTranslateRounded />} 
            label={""} 
            deleteIcon={loading ? <CloudSyncOutlined /> : lang == sLang ? <ToggleOff /> : <ToggleOn />}
            onDelete={() => setLang(sLang == lang ? dLang : sLang)}
            style={{position: "absolute", right: "-8px"}}
            size={"small"}
            variant={loading || sLang == lang ? 'outlined' : 'filled'}
            color={sLang == lang ? "warning" : "success"}
        />
    </>
}

const ScrollDialog = (props) => {

    useEffect(() => {
        if (props.open) {
            const { current: descriptionElement } = props.elemRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [props.open]);

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText 
                    id="scroll-dialog-description"
                    ref={props.elemRef}
                    tabIndex={-1}
                    paragraph={false}
                    component={props?.component || 'div'}
                >
                    {props.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.buttons}
            </DialogActions>
        </Dialog>
    );
}

const MarkReadyButton = (props) => {

    const [open, setOpen] = useState(false);

    console.log(props.user);

    const handleClose = (e) => {
        setOpen(false);
        props.handleMarkReady(props.user);
    }

    const {t} = useTranslation('bookings');

    const descriptionElementRef = useRef(null);
    const responsible_user = props.user.users.find(e => e['@id'] == props.responsible);

    if (props.clean || !responsible_user)
        return <></>

    return <>
        <TableRow>
            <TableCell colSpan={8}>
                <Button 
                    disabled={!props.survey.guests || !props.survey.cleaned || !props.survey.rating || !props.survey.openings || !props.survey.heating || !props.survey.curtains || props.clean}
                    onClick={() => {
                        if (props.responsible != props.user['@id'] && !props.user.roles.includes('ROLE_ADMIN'))
                            setOpen(true);
                        else
                            props.handleMarkReady(props.user);
                    }}
                    style={{width: "100%"}}
                    color={"secondary"}
                >
                    {t('mark-ready')}
                </Button>
            </TableCell>
        </TableRow>
        <ScrollDialog
            open={open}
            handleClose={() => setOpen(false)}
            elemRef={descriptionElementRef}
            title={
                <>
                    <WarningAmber color={"secondary"} /> <Trans t={t} i18nKey='warn-not-responsible-user'
                        values={{user_name: responsible_user.name}}
                        components={{bold: <b style={{color: '#f50057'}} />}}
                    />
                </>
            }
            content={
                <>
                <Typography gutterBottom>
                    <Trans t={t} i18nKey="warn-on-behalf-of"
                        components={{bold: <b style={{color: "#f50057"}} />}}
                    />
                </Typography>
                <Typography gutterBottom>
                    <span style={{color: "#f50057", paddingLeft: '2em'}}>- {responsible_user.name}</span>
                </Typography>
                <Typography gutterBottom>
                    <Trans t={t} i18nKey="warn-info-req"
                        components={{bold: <b />}}
                    />
                </Typography>
                <Typography>
                    <b>{t('warn-this-includes')}:</b>
                </Typography>
                <Typography>
                    - <Trans t={t} i18nKey={"warn-cleaned"}
                        components={{ bold: <b />}}
                    />
                </Typography>
                <Typography>
                    - <Trans t={t} i18nKey={"warn-prepared"}
                        components={{ bold: <b />}}
                    />
                </Typography>
                <Typography>
                    - <Trans t={t} i18nKey={"warn-closed"}
                        components={{ bold: <b />}}
                    />
                </Typography>
                <Typography>
                    - <Trans t={t} i18nKey={"warn-notes"}
                        components={{ bold: <b />}}
                    />
                </Typography>
                <Typography gutterBottom>
                    - <b>+++</b>
                </Typography>
                <Typography gutterBottom>
                <Trans t={t} i18nKey={"warn-agree"}
                        components={{ bold: <b style={{color: "#f50057"}} />}}
                        values={{user_name: responsible_user.name}}
                    />
                </Typography>
                <Typography gutterBottom>
                    {t('warn-contact-cleaner')}
                </Typography>
                </>
            }
            buttons={
                <>
                    <Button onClick={() => setOpen(false)} color={"primary"}>Cancel</Button>
                    <Button onClick={handleClose} color={"secondary"}>Proceed as {}</Button>
                </>
            }
        />
    </>
}

const StipulatedTimeUsage = (props) => {

    const [open, setOpen] = useState(false);

    const [hours, updateHours] = useState(Number(Math.floor(props.timeUsage /(1000 * 60 * 60)) % 24).toFixed(0) || 0)
    const [minutes, updateMinutes] = useState(Number((props.timeUsage / (1000 * 60)) % 60).toFixed(0) || 0)

    const {t} = useTranslation('bookings');

    const descriptionElementRef = useRef(null);
    const allowedToChange = props.user.roles.includes('ROLE_ADMIN') || (
        (props.user.roles.includes('ROLE_MANAGER')) || 
        (props.user?.id == props?.responsible?.id && !props.cleaned)
    ) && (!props.cleaned && DayMonthYear(props.booking.checkout) == DayMonthYear(new Date()));

    const handleClose = (e) => {
        if (!allowedToChange)
            return;

        setOpen(false);
        props.updateHandler(hours, minutes)
    }

    const handleTimeChange = (e) => {
        updateHours(e.toDate().getHours());
        updateMinutes(e.toDate().getMinutes());
    }

    const dh = (new Date()).setHours(hours, minutes);

    return <>
        <List>
            <ListItem onClick={() => allowedToChange && setOpen(true)} style={{cursor: 'pointer'}}>
                <ListItemAvatar>
                    <Avatar
                        style={{
                            backgroundColor: pink[500]
                        }}
                    >
                        {props.icon}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={
                        `${hours == 0 ? '' : `${hours} ${t('hour', {count: hours})}`}${hours != 0 ? ' ' : ''}${minutes != 0 || (minutes == 0 && hours == 0) ? `${minutes} ${t('minute', {count: minutes})}` : ''}`
                    } 
                    secondary={props.text}
                />
            </ListItem>
        </List>
        <ScrollDialog
            open={open}
            handleClose={() => setOpen(false)}
            elemRef={descriptionElementRef}
            title={
                <><Timer />{t('report-time-difference')}</>
            }
            content={
                <>
                    <Box alignItems="center" display="flex" style={{padding: "10px"}}>
                        <Box>
                            <Feedback style={{margin: '10px ', color: pink[500] }}/>
                        </Box>
                        <Box>
                            <Trans t={t} i18nKey='time-change-note'
                                components={{bold: <b />}}
                            />
                        </Box>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterMoment} style={{padding: '10px'}}>
                        <TimePicker
                            InputProps={{
                                variant: "filled"
                            }}
                            label={
                                <span style={{color: pink[500]}}>
                                    <Trans t={t} i18nKey='actual-time-usage'
                                        components={{bold: <b />}}
                                    />
                                </span>
                            }
                            onChange={handleTimeChange}
                            value={dh}
                            inputFormat={'HH:mm'}
                            renderInput={(p) => <TextField {...p} />}
                            ampm={false}
                            timezone="Europe/Oslo"
                            
                        />
                    </LocalizationProvider>
                </>
            }
            buttons={
                <Button onClick={handleClose} color={"primary"}>{t('save')}</Button>
            }
        />
    </>
}

const GuestRoomsSplit = (props) => {

    const guests = props?.guests ?? ((
        props.booking?.nextCheckin?.guests || false) ? props.booking.nextCheckin.guests : (
            props.booking?.nextCheckin?.guests || props.booking.property?.maxGuest || props.property.numBedrooms
    ));

    let rooms = props?.bedrooms ?? (
        (props.booking?.nextCheckin?.bedrooms || false) ? 
            props.booking.nextCheckin.bedrooms :  props.property.numBedrooms
    );

    if (rooms > guests)
        rooms = guests;

    return <ButtonGroup color="secondary" aria-label={"primary button group"} variant={"text"}>
        <Button
            startIcon={<Hotel />}
        >
            {guests}
        </Button>
        <Button color={"primary"}>{
            rooms < guests || [0, 1].includes(guests) ?
                <ArrowForward />
        :
            <CallSplit style={{transform: 'rotate(90deg)'}} />
        }</Button>
        <Button
            startIcon={<NightShelter />}
        >
            {rooms > props.property.numBedrooms ? props.property.numBedrooms : rooms} room
        </Button>
        {rooms > props.property.numBedrooms ?
            <Button
                endIcon={<AirlineSeatFlat />}
            >
                + {rooms - props.property.numBedrooms} xtra
            </Button>
            :
                null
        }
    </ButtonGroup>
}

const TableCellHeader = (props) => (
    <TableCell {...props}>
        <b>{props.children}</b>
    </TableCell>
)

const ShortAddr = (addr) => (
    addr.split(",")[0]
)

const Day = (props) => {

    let classes = useStyles();
    const [open, setOpen] = useState(props.first);
    const {t} = useTranslation('bookings');
    useEffect(() => {
        setOpen(props.expanded || props.first);
    }, [props.expanded])

    return (
        <>
            <ListItem button 
                onClick={e => setOpen(!open)}
                selected={open}
            >
                <ListItemIcon>
                    <CalendarToday style={{color: '#3f51b5'}} />
                </ListItemIcon>
                <ListItemText 
                    primary={<>
                        <b>
                            {t(moment(props.date).format('dddd'))}
                        </b><span>:  {DayMonthYear(props.date)}</span>
                    </>} 
                    secondary={`${props.bookings.length}` + " " + props.listType + (open ? ": " + props.bookings.filter(b => (props.listType.includes('checkout') ? !b.clean : !b.ready)).length + ` ${t('left-to-clean')}` : "")}
                />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout={"auto"} unmountOnExit>
                <List component={"div"}>
                    {props.bookings.map(booking => (
                        <Booking t={t} user={props.user} future={props.future} booking={{...booking}} type={props.listType} classes={classes} key={booking['@id']} openReLoginHandler={props.openReLoginHandler}/>
                    ))}
                </List>
            </Collapse>
            <Divider />
        </>

    );
}

class BookingsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: this.props.type == "checkin" ? this.props.type : "checkout",
            loading: true,
            data: [],
            from: YearMonthDay(new Date()),
            clean: false,
            totalItems: 0,
            future: true,
            expanded: false
        };
        this.load = this.load.bind(this);
        this.toggleFuture = this.toggleFuture.bind(this);
        this.toggleExpanded = this.toggleExpanded.bind(this);
    }
    
    toggleExpanded() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    toggleFuture() {
        this.setState({
            future: !this.state.future,
            data: [],
        }, () => this.setNext());
    }

    setNext() {
        
        let limit = "&responsible.id=" + this.context['@id'];

        if (this.context.roles.includes("ROLE_MANAGER") && !this.context.roles.includes('ROLE_ADMIN'))
            limit = "&responsible.company=" + this.context.company;

        //let url = (state) => "/api/bookings?blocking=false&order[" + state.type + `]=${state.future ? 'asc' : 'desc'}&` + state.type + `[${state.future ? 'after' : 'before'}]=` + state.from + "&" + limit
        
        if (this.context.roles.includes('ROLE_ADMIN'))
            limit = '';

        let url = ({from, future, type}) => `/api/bookings?blocking=false&${
            type.includes('checkout') ? 'timeStart' : 'checkin'
        }[${
            future ? 'after' : 'before'
        }]=${from}&order[${
            type
        }]=${future ? 'asc' : 'desc'}${limit}`;

        this.setState(state => ({
            next: url(state)
        }));

        this.load();
    }

    componentDidMount() {
        this.setNext();
    }

    load() {
        this.setState({
            loading: true,
        }, () => {
            if(this.state.next) { 
                axios.get(this.state.next).then(resp => {
                    console.log(this.state.next, resp.data);
                    this.setState(state => ({...{
                        data: {...this.mapData(state.data, resp.data['hydra:member'])},
                        next: resp.data['hydra:view']['hydra:next'] ?? false,
                        totalItems: resp.data['hydra:totalItems'],
                        loading: false,
                    }}));
                }).catch (err => {
                    this.props.openReLoginHandler(err.response.status == 401)
                })
            }
        });
    }

    mapData(data, newData) {
        newData.forEach(elem => {

            const date = new Date(this.state.type.includes('checkout') ? elem?.cleaningDate : elem.checkin);
            const match = YearMonthDay(date);
        
            if (data[match] ?? false) {
                if (!(data[match] ?? []).find(e => e['@id'] == elem['@id']))
                    data[match] = [...data[match], elem].sort((a,b) => {
                        if (this.props.type == "checkin")
                            return new Date(a.checkin) - new Date(b.checkin);                
                        if (a.nextCheckin == null || b.nextCheckin == null)
                            return (new Date(a.checkout) - new Date(b.checkout));                
                        if (HourMinute(a.checkout) == HourMinute(b.checkout))
                            return (new Date(a.nextCheckin.at) - new Date(a.checkout)) - (new Date(b.nextCheckin.at) - new Date(b.checkout));
                        return (new Date(a.checkout) - new Date(b.checkout));
                    });;
            } else
                data[match] = [elem];
        })
        return Object.keys(data).sort((a, b) => this.state.future ? (new Date(a) - new Date(b)) : (new Date(b) - new Date(a))).reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
        }, {});
    }
    render() {
        return <UserContext.Consumer>{ user => 
            <>
                <BookingsListView {...{...this.state, user: user}} loadHandler={this.load} openReLoginHandler={this.props.openReLoginHandler}/>
                <SpeedDial
                    ariaLabel="Misc"
                    sx={{ position: 'fixed', bottom: 16, right: 16 }}
                    icon={<SpeedDialIcon />}
                >
                    <SpeedDialAction
                        key={this.state.type}
                        icon={this.state.future ? <History/> : <Update />}
                        tooltipTitle={`${this.state.future ? 'Past' : 'Future'} ${this.state.type}s`}
                        onClick={() => this.toggleFuture()}
                    />
                    <SpeedDialAction
                        key={"logout"}
                        icon={<Logout />}
                        tooltipTitle={`Logout`}
                        onClick={() => user.logout()}
                    />
                    <SpeedDialAction
                        key={"expand_all"}
                        icon={<Expand />}
                        tooltipTitle={`${this.state.expanded ? 'Expand' : 'Collapse'}`}
                        onClick={() => this.toggleExpanded()}
                    />
                </SpeedDial>
            </>
        }</UserContext.Consumer>
    }
}
BookingsList.contextType = UserContext;

const ContactButtonGroup = (props) => {

    const [showContacts, setShowContacts] = useState(false);
    const descriptionElementRef = useRef(null);
    const user = props.user;
    const ruser = props?.requestingUser;

    const whatsAppUser = user.using_whatsapp && !ruser || (user.using_whatsapp && ruser && ruser.using_whatsapp);

    const cntBtns = (props?.roles||[]).includes("ROLE_MANAGER") !== true ? [
        <Button color={"secondary"} key="icon" onClick={() => setShowContacts(true)}><Contacts /></Button>
    ]:[
        <Button color={"secondary"} aria-label={"call"} key={"call"}>
            <Call onClick={() => window.location.href=`tel:${user.phone}`} />
        </Button>,
        <Button color={"secondary"} aria-label={"sms"} key={"sms"} style={whatsAppUser ? {backgroundColor: '#43c554', borderColor: '#128c7e'} :{}}>
            { whatsAppUser ? 
                <WhatsApp onClick={() => window.open(`https://wa.me/${encodeURI(user.phone)}?text=${props?.subject || ""}`, '_blank')} />
            :
                <Sms onClick={() => window.location.href=`sms:${user.phone}?&body=${props?.subject || ""}`} />
            }
        </Button>,
        <Button color={"secondary"} aria-label={"email"} key={"email"}>
            <AlternateEmail onClick={() => window.location.href=`${`mailto:${encodeURI(user.email)}`}?subject=${props?.subject || ""}`} />
        </Button>
    ];

    const btns = [
        ...cntBtns,
        <Button color={"primary"} key={"user"}
            // value={0}
            {...props.userButtonProps}
            style={{...props?.userButtonProps?.style, width: "100%"}}
        >
            {user.name} {props?.extra}
        </Button>
    ];

    return (
        <>
        <ButtonGroup color="secondary" aria-label={"outlined primary button group"} variant={"contained"} style={{width: "100%"}}>
            {btns}
        </ButtonGroup>
        { !(props?.roles || []).includes("ROLE_MANAGER") ? 
            <ScrollDialog
                key={"scroll"}
                open={showContacts}
                handleClose={() => setShowContacts(false)}
                elemRef={descriptionElementRef}
                title={"Contact colleagues"}
                content={
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {props.users.filter(emp => (ruser?.id != emp.id) || true).filter(emp => emp.roles.length > 0 && (emp.company == user.company || (emp.company == 'GV AS' && emp.roles.includes('ROLE_MANAGER')))).sort((au, bu) => {
                                    if (au.company == 'GV AS')
                                        return -1;
                                    if (bu.company == 'GV AS')
                                        return 1;
                                    if (au.name.toUpperCase() < bu.name.toUpperCase())
                                        return -1;
                                    if (au.name.toUpperCase() > bu.name.toUpperCase())
                                        return 1;
                                    return 0;
                                }).map(u => (
                                    <TableRow key={u.id}>
                                        <TableCell style={{padding: '5px 0'}}>
                                            <ContactButtonGroup
                                                user={u}
                                                requestingUser={user}
                                                users={props.users}
                                                roles={['ROLE_MANAGER']}
                                                extra={
                                                    <span style={{fontSize: "0.5em"}}>» {u.company}</span>
                                                }
                                                userButtonProps={{
                                                    color: "primary",
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                buttons={
                    <Button onClick={() => setShowContacts(false)}>Close</Button>
                }
            />
        : null
        }
        </>
    );
}

const BookingsListView = (props) => {

    let objs = Object.keys(props.data).length > 0 ? Object.keys(props.data).reduce((sum, key) => {
        return sum + props.data[key].length;
    }, 0) : 0;
    
    const {t} = useTranslation('bookings');

    let classes = useStyles();
    let history = useNavigate();

    let first = true;

    return (
        <div>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader" className={classes.paper} style={{
                            backgroundColor: "#fafafa",
                            marginTop: 0,
                            borderBottom: "1px solid rgba(245,0,87,1)"
                        }}
                        color={props.future ? "primary" : "default"}
                    >
                        <Stack direction="row" alignItems="center" gap={1}>
                            { props.type == 'checkout' ?
                                null
                            :
                                <Login />
                            }
                            <Typography component="h1" variant="h5">
                                <span onClick={() => {
                                    history("/");
                            }} style={{cursor: "pointer"}}>
                                    {props.future ? t('future') : t('past') } {props.type.charAt(0).toLowerCase() + props.type.slice(1) + (objs > 1 ? "s" : "")}
                                </span>
                            </Typography>
                            { props.type == 'checkout' ?
                                <Logout />
                            :
                                null
                            }
                        </Stack>
                        <Divider />
                    </ListSubheader>
                }
                className={classes.root}
            >
                {props.totalItems == 0 ?
                    <ListItem button>
                        <ListItemIcon style={{color: "green"}}>
                            { props.loading ? 
                                <Autorenew />
                            :
                                <CalendarToday />
                            }
                        </ListItemIcon>
                        <ListItemText
                            primary={props.loading ? t('loading-results') : t('nothing-here-yet')} 
                            secondary={props.loading ? t('wait-for-results') : t('contact-manager-for-tasks')}
                        />
                    </ListItem>
                :
                    Object.keys(props.data).map(v => {
                        let isFirst = first;
                        first = false;
                        return <Day key={v} user={props.user} future={props.future} expanded={props?.expanded || false} first={isFirst} date={v} bookings={props.data[v]} listType={`${props.type}${objs > 1 ? "s" : ""}`} openReLoginHandler={props.openReLoginHandler}  />
                    })
                }
                {objs == props.totalItems ? 
                    ""
                :
                    <ListItem button
                        onClick={e => props.loadHandler()}
                        disabled={!props.next}
                    >
                        <b>{t('load-more')} ({`${objs}/${props.totalItems}`})</b>
                    </ListItem>
                }
            </List>
            <LinearProgress variant={"determinate"} color="secondary" value={(objs/props.totalItems)*100} />
        </div>
    );
}

const BookingsType = (props) => {
    let {type} = props.type ? {type: props.type} : useParams();
    const setMaxWidth = useContainerContext();

    useEffect(() => setMaxWidth('md'), [])

    if (!["checkout", "checkin"].includes(type))
        type = "checkout";

    return <BookingsList type={type} openReLoginHandler={props.reLoginHandler} />
}

const Bookings = (props) => {

    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const match = useMatch('/bookings/*');
    let history = useNavigate();
    const {t, i18n} = useTranslation('bookings');

    const path = match.pathname

    useEffect(() => {
        i18n.changeLanguage(props.lang).then(t => {})
    }, [])

    useEffect(() => {
        const to = setTimeout(() => {
            axios.get('/api/users/ping').catch(err => {
                if (err.response.status == 401) {
                    setOpen(true);
                    clearTimeout(to);
                }
            });
        }, 20000);
        return () => clearTimeout(to);
    }, [open]);

    return (
        <UserContext.Consumer>
            {user => <div>
                    <CssBaseline />
                    <Routes>
                        <Route exact path={"/"} element={ user.roles.includes("ROLE_ADMIN") ?
                            <div>
                                <Paper className={classes.paper} style={{width: "100%", padding: "20px"}}>
                                    <Typography component={"h1"} variant={"h5"}>
                                        Choose list type
                                    </Typography>
                                    <ButtonGroup size="large" aria-label="large outlined primary button group">
                                        <Button color="primary" onClick={() => {
                                                history(`${path}/checkin`)
                                            }}
                                            startIcon={<Login />}
                                        >
                                            {t('Check-ins')}
                                        </Button>
                                        <Button color="info" onClick={() => {
                                                history(`${path}/invoicing`)
                                            }}
                                            startIcon={<MonetizationOn />}
                                        >
                                            {t('Invoicing')}
                                        </Button>
                                        <Button color="secondary" onClick={() => {
                                                history(`${path}/checkout`)
                                            }}
                                            endIcon={<Logout />}
                                        >
                                            {t('Check-outs')}
                                        </Button>
                                    </ButtonGroup>
                                </Paper>
                            </div>
                        :
                            user.roles.includes('ROLE_ACCOUNTANT') ?
                                <Invoicing />
                            :
                                <BookingsType type="checkout" reLoginHandler={setOpen}/>
                        } />
                        <Route path={`/invoicing/*`} element={
                            <Invoicing />
                        } />
                        <Route path={`/:type`} element={
                            <BookingsType type={false} reLoginHandler={setOpen}/>
                        } />
                    </Routes>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        open={open}
                        autoHideDuration={4000}
                        onClose={() => {
                            setOpen(false);
                            user.logout();
                            history('/login');
                        }}
                        message={
                            <span>
                                <b style={{color: '#f50057'}}>{t('inactivity-warning')}</b><br />
                                <Trans t={t} i18nKey='inactivity-info'
                                    components={{bold: <b />}}
                                />
                            </span>
                        }
                        action={
                                <Button color="secondary" size="small" onClick={() => {
                                    setOpen(false);
                                    user.logout();
                                    history('/login');
                                }}
                                    endIcon={<Login />}
                                >
                                    {t('login-again')} 
                                </Button>
                        } />
                </div>
            }
        </UserContext.Consumer>
    )
}

export default Bookings;
