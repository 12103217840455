/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');

import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import Page from "./Booking/Page";
import "./translations/i18n"

// Comment
const root = ReactDOM.createRoot(document.getElementById('aras'));

root.render(
    <StrictMode>
        <Page />
    </StrictMode>
);
